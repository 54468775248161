;
$(document).ready(function() {

    var DataTable = $.fn.dataTable;

    $.extend( true, DataTable.Buttons.defaults, {
        dom: {
            container: {
                className: 'dt-buttons btn-group'
            },
            button: {
                className: 'btn btn-primary px-2'
            },
            collection: {
                tag: 'div',
                className: 'dt-button-collection dropdown-menu',
                button: {
                    tag: 'a',
                    className: 'dt-button dropdown-item',
                    active: 'active',
                    disabled: 'disabled'
                }
            }
        }
    } );





});