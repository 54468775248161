;

function cms_imageManager_initCropInModal() {

    $("._cropInModal").off("click",  "**");
    $("._cropInModal").on("click",  function (e) {
        var self = $(this);

        if (dataPropExists(self, 'preventdefault') && getDataProp(self, 'preventdefault')) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        var ops = {
            backdrop: 'true',
            keyboard: 'true',
            modalClass: '',
            modalTitle: '',
            modalCloseButtonLabel: 'close',
            modalBody: '',
            modalCancelButtonClass: '',
            modalCancelButtonLabel: 'cancel',
            modalOkButtonClass: '',
            modalOkButtonLabel: 'ok',
            modalcontenturl: '',
            uuid : '',
            cropurl : '',
            cropprocessurl : ''
        };


        if (dataPropExists(self,'modalbackdrop') && in_array(self.data('modalbackdrop'),[ true, false, 'static' ])) {
            ops.backdrop = self.data('modalbackdrop');
        }

        if (dataPropExists(self,'modalkeyboard') && in_array(self.data('modalkeyboard'),[ true, false ])) {
            ops.keyboard = self.data('modalkeyboard');
        }

        if (dataPropExists(self,'modalclass')) {
            ops.modalClass = self.data('modalclass');
        }
        if (dataPropExists(self,'modaltitle') && strlen(self.data('modaltitle'))>0) {
            ops.modalTitle = self.data('modaltitle');
        }
        if (dataPropExists(self,'modalbody') && strlen(self.data('modalbody'))>0) {
            ops.modalBody = self.data('modalbody');
        }
        if (dataPropExists(self,'modalclosebuttonlabel') && strlen(self.data('modalclosebuttonlabel'))>0) {
            ops.modalCloseButtonLabel = self.data('modalclosebuttonlabel');
        }
        if (dataPropExists(self,'modalcancelbuttonclass') && strlen(self.data('modalcancelbuttonclass'))>0) {
            ops.modalCancelButtonClass = self.data('modalcancelbuttonclass');
        }
        if (dataPropExists(self,'modalcancelbuttonlabel') && strlen(self.data('modalcancelbuttonlabel'))>0) {
            ops.modalCancelButtonLabel = self.data('modalcancelbuttonlabel');
        }
        if (dataPropExists(self,'modalokbuttonclass') && strlen(self.data('modalokbuttonclass'))>0) {
            ops.modalOkButtonClass = self.data('modalokbuttonclass');
        }
        if (dataPropExists(self,'modalokbuttonlabel') && strlen(self.data('modalokbuttonlabel'))>0) {
            ops.modalOkButtonLabel = self.data('modalokbuttonlabel');
        }
        if (dataPropExists(self,'uuid') && strlen(self.data('uuid'))>0) {
            ops.uuid = self.data('uuid');
        }
        if (dataPropExists(self,'cropurl') && strlen(self.data('cropurl'))>0) {
            ops.cropurl = self.data('cropurl');
        }
        if (dataPropExists(self,'cropprocessurl') && strlen(self.data('cropprocessurl'))>0) {
            ops.cropprocessurl = self.data('cropprocessurl');
        }

        if (elementExists('#dynamicImageCropModal')) {
            $('#dynamicImageCropModal').remove();
        }

        var modal = '<div class="modal fade" id="dynamicImageCropModal" ' +
            'data-uuid="' + ops.uuid + '" ' +
            'data-cropurl="' + ops.cropurl + '" ' +
            'data-cropprocessurl="' + ops.cropprocessurl + '" ' +
            'data-cropdata="" ' +
            'data-backdrop="' + ops.backdrop + '" ' +
            'data-keyboard="' + ops.keyboard + '" ' +
            'data-contenturl="' + ops.modalcontenturl + '" ' +
            ' role="dialog" aria-labelledby="dynamicImageCropModalTitle" aria-hidden="true">' +
            '        <div class="modal-dialog ' + ops.modalClass + '" role="document">' +
            '            <div class="modal-content">';

        if (strlen(ops.modalTitle)>0) {
            modal += '<div class="modal-header">' +
                '<h5 class="modal-title" id="dynamicImageCropModalTitle">' + ops.modalTitle + '</h5>' +
                '<button type="button" class="close" data-dismiss="modal" aria-label="' + ops.modalCloseButtonLabel + '"><span aria-hidden="true"></span></button>' +
                '</div>';
        }

        modal += '<div class="modal-body"><div class="imageCropContainer">' + ops.modalBody + '</div><div class="mt-2 d-none respMsg"></div></div>' +
            '<div class="modal-footer justify-content-between">' +
            '<button type="button" class="btn btn-secondary ' + ops.modalCancelButtonClass + '" data-dismiss="modal" title="' + ops.modalCancelButtonLabel + '">' + ops.modalCancelButtonLabel + '</button>';

        modal +=        '<button type="button" class="btn btn-primary ' + ops.modalOkButtonClass + ' _process" title="' + ops.modalOkButtonLabel + '">' + ops.modalOkButtonLabel + '</button>';

        modal +=    '</div>' +
            '</div>' +
            '</div>' +
            '</div>';




        $('body').append($(modal));

        $('#dynamicImageCropModal').on('shown.bs.modal', function (e) {
            config.userInteractionStarted = true;
            destroyToolTips('[data-toggle="tooltip"]');
            var m = $('#dynamicImageCropModal');

            m.find('.respMsg').removeClass('d-none').addClass('justify-content-center, text-center').html(loader);

            if (dataPropExists(m,'uuid') && dataPropExists(m,'cropurl') && dataPropExists(m,'cropprocessurl') && strlen(m.data('uuid'))>0 && strlen(m.data('cropurl'))>0 && strlen(m.data('cropprocessurl'))>0) {
                var url = m.data('cropurl');
                var uuid = m.data('uuid');

                _request(url, 'post', { uuid : uuid }, function(r){

                    if (r.status) {

                        m.find('.respMsg').addClass('d-none').removeClass('justify-content-center, text-center').html('');
                        m.find('.imageCropContainer').html('<img id="__imageToCrop" src="' + r.data.url + '" />');


                        var cropper = new Cropper(document.getElementById('__imageToCrop'), {
                            viewMode : 1,
                            dragMode : 'crop',
                            aspectRatio : 1 / 1,
                            movable : false,
                            rotatable : false,
                            zoomable : false,

                            ready() {
                                m.data('cropdata',this.cropper.getData(true));
                            },
                            cropend() {
                                m.data('cropdata',this.cropper.getData(true));
                            }
                        });

                    } else {
                        m.modal('hide');
                    }

                }, {});

            } else {
                m.modal('hide');
            }

        });

        $('#dynamicImageCropModal').modal('show');

    });

    $("body").off("click", "#dynamicImageCropModal ._process", "**");
    $("body").on("click", "#dynamicImageCropModal ._process", function () {
        var self = $(this);
        var m = $('#dynamicImageCropModal');

        self.addClass('d-none');
        m.find('.respMsg').removeClass('d-none').addClass('justify-content-center, text-center').html(loader);
        m.find('.imageCropContainer').addClass('d-none');

        _request(m.data('cropprocessurl'),'post',{ uuid : m.data('uuid'), cropData : m.data('cropdata') },function(r){

            if (r.status) {
                $('[data-uuid="' + m.data('uuid') + '"]').closest('.img-covered').data('imgSrc',r.data.thumb);
                populateImages('.img-covered');
                m.modal('hide');
            } else {
                m.find('.respMsg').removeClass('justify-content-center, text-center').html(wrapResponse(r.msg,'error'));
                m.find('.imageCropContainer').removeClass('d-none');
                self.removeClass('d-none');
            }

        },{});

    });

}

function cms_removeImageFromImageManagerList(e) {
    var cont = e.closest('.tools').closest('ul');
    var el = e.closest('.tools').closest('li');

    showLoader();

    _request(e.attr('href'),'post',{ uuid : e.data('uuid') },function(r){

        if (r.status) {
            el.remove();

            if (cont.find('li').length < 1) {
                reloadUrl();
            }

        }

        hideLoader();
        hideConfirmModal();

    },{});

}

function cms_removeImageFromGalleryList(e) {
    var cont = e.closest('.tools').closest('ul');
    var el = e.closest('.tools').closest('li');

    showLoader();

    _request(e.attr('href'),'post',{ relationId : e.data('uuid'), galleryUuid: e.data('galleryuuid') },function(r){

        if (r.status) {
            el.remove();

            if (cont.find('li').length < 1) {
                reloadUrl();
            }

        }

        hideLoader();
        hideConfirmModal();

    },{});

}

function cms_imageManager_getAllImageTags(element) {

    if (elementExists(element) && dataPropExists(element,'source')) {

        var e = $(element);
        showLoader();

        _request(e.data('source'),'post',{},function(r){
            e.empty();

            if (_.isObject(r.data) && _.size(r.data)>0) {
                _.map(r.data, function(v,k){

                    e.append('<option value="' + v.id + '">' + v.admName + '</option>');

                });
            }

            e.trigger('change');
            hideLoader();
        },{});

    }

}

function cms_imageManager_getImagesByTagsRequest(container, tags, moveOnlyHandle) {

    if (elementExists(container) && dataPropExists(container,'source')) {
        container = $(container);
        var resultHtml = '';

        container.html(loader).addClass('d-flex justify-content-center');

        if (elementExists('[name="cms_imageManager_selectedImages"]')) {
            $('[name="cms_imageManager_selectedImages"]').val('[]');
        }

        _request(container.data('source'), 'post', { 'tags' : tags }, function(r) {

            if (r.status && _.isObject(r.data) && _.size(r.data)>0) {

                resultHtml = '<ul class="userFilesThumbnailList cmsImageManager">';

                var propertiesHolder = container.closest('.cms_image_list');

                _.map(r.data, function(v,k) {

                    resultHtml += '<li data-uuid="' + v.uuid + '">' +
                        '<div class="thumb img-covered" data-img-src="' + v.thumb + '" data-src="' + v.img + '" data-toggle="tooltip" data-placement="top" data-html="true"';

                    if (moveOnlyHandle == false) {
                        resultHtml += ' data-fancybox-trigger="gallery" data-fancybox="gallery" data-type="image" >';
                    } else {
                        resultHtml += ' data-selectable="true" >';
                    }


                    if (moveOnlyHandle == false) {
                        resultHtml += '<div class="tools">' +

                            '<a href="javascript:;" ' +
                            'class="btn btn-primary withIcon iconOnLeft iconOnlyButton cropIcon _cropInModal" ' +
                            'title="' + Translator.trans('generic.button.crop',{},'fe_texts') + '" ' +
                            'data-preventdefault="true" ' +
                            'data-modalbackdrop="false" ' +
                            'data-modalkeyboard="false" ' +
                            'data-modalclass="modal-xxl" ' +
                            'data-uuid="' + v.uuid + '" ' +
                            'data-cropurl="' + propertiesHolder.data('cropurl') + '" ' +
                            'data-cropprocessurl="' + propertiesHolder.data('cropprocessurl') + '" ' +
                            'data-loadmodalcontentfrom="href" ' +
                            'data-modalclosebuttonlabel="' + Translator.trans('generic.button.close',{},'fe_texts') + '" ' +
                            'data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel',{},'fe_texts') + '" ' +
                            'data-modalokbuttonlabel="' + Translator.trans('generic.button.ok',{},'fe_texts') + '" ' +
                            'data-modalbody="" ' +
                            '></a>' +

                            '<a href="' + propertiesHolder.data('editurl') + '/' + v.uuid + '" ' +
                            'class="btn btn-primary withIcon iconOnLeft iconOnlyButton editIcon _editInModal" ' +
                            'title="' + Translator.trans('generic.button.edit',{},'fe_texts') + '" ' +
                            'data-preventdefault="true" ' +
                            'data-modalbackdrop="false" ' +
                            'data-modalkeyboard="false" ' +
                            'data-loadmodalcontentfrom="href" ' +
                            'data-modalclosebuttonlabel="' + Translator.trans('generic.button.close',{},'fe_texts') + '" ' +
                            'data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel',{},'fe_texts') + '" ' +
                            'data-modalokbuttonlabel="' + Translator.trans('generic.button.ok',{},'fe_texts') + '" ' +
                            'data-modaltitle="' + ucfirst(Translator.trans('generic.button.edit',{},'fe_texts')) + '" ' +
                            'data-modalbody="" ' +
                            '></a>' +

                            '<a href="' + propertiesHolder.data('deleteurl') + '/' + v.uuid + '" ' +
                            'class="btn btn-primary withIcon iconOnLeft iconOnlyButton deleteIcon _showConfirmModal" ' +
                            'title="' + Translator.trans('generic.button.delete',{},'fe_texts') + '" ' +
                            'data-preventdefault="true" ' +
                            'data-processaction="callback" ' +
                            'data-uuid="' + v.uuid + '" ' +
                            'data-callback="cms_removeImageFromImageManagerList" ' +
                            'data-modaltitle="' + Translator.trans('modal.confirmation.removeRecordsTitle',{},'fe_texts') + '" ' +
                            'data-modalbody=\'' + Translator.trans('modal.confirmation.removeRecordsBody',{},'fe_texts') + '\' ' +
                            'data-modalclosebuttonlabel="' + Translator.trans('generic.button.close',{},'fe_texts') + '" ' +
                            'data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel',{},'fe_texts') + '" ' +
                            'data-modalokbuttonlabel="' + Translator.trans('generic.button.ok',{},'fe_texts') + '" ' +
                            '></a>' +


                            '</div>';
                    } else {

                    }

                    resultHtml +=  '</div>' +
                        '</li>';


                });

                resultHtml += '</ul>';

            } else {
                resultHtml = '<div class="alert alert-info mb-0">' + Translator.trans('generic.text.noRecords', {}, 'fe_texts') + '</div>';
            }

            container.html(resultHtml).removeClass('d-flex justify-content-center');

            populateImages('.img-covered');

            initShowConfirmModal();
            initEditInModal();
            cms_imageManager_initCropInModal();

            $('.cmsImageManager div[data-selectable="true"]').closest('li').each(function(){
                var self = $(this);

                if (dataPropExists(self,'uuid')) {

                    self.off("click",  "**");
                    self.on("click",  function (e) {

                        $(this).toggleClass('selected');
                        var uuid =  self.data('uuid');

                        if (elementExists('[name="cms_imageManager_selectedImages"]')) {
                            var s = $('[name="cms_imageManager_selectedImages"]').val();

                            if (!isJSONString(s)) {
                                s = '[]';
                            }

                            s = JSON.parse(s);

                            s = _.without(s,uuid);

                            if ($(this).hasClass('selected')) {
                                s.push(uuid);
                            }


                            $('[name="cms_imageManager_selectedImages"]').val(JSON.stringify(s));

                        }



                    });
                }



            });

            hideLoader();
        }, {});


    }




}

function cms_imageManager_getAllImagesByTags(selected,container,moveOnlyHandle) {

    if (elementExists(container) && dataPropExists(container,'source')) {

        if (_.size(selected) > 0) {
            cms_imageManager_getImagesByTagsRequest(container, selected, moveOnlyHandle);
        } else {
            cms_imageManager_getAllUntaggedImages(container, ['-1'], moveOnlyHandle);
        }

    }
}

function cms_imageManager_getAllUntaggedImages(container, moveOnlyHandle) {
    cms_imageManager_getImagesByTagsRequest(container, ['-1'], moveOnlyHandle);
}

function cms_imageManager_getAllImages(container, moveOnlyHandle) {
    cms_imageManager_getImagesByTagsRequest(container, ['0'], moveOnlyHandle);
}