;
function initShowConfirmModal() {
    runShowConfirmModal("._showConfirmModalBinded", true);
    runShowConfirmModal("._showConfirmModal");
}

function runShowConfirmModal(e, binded) {

    if (typeof binded === 'undefined') { binded = false; }

    if (binded) {

        $("body").off("click", e, "**");
        $("body").on("click", e, function (event) {

            if (dataPropExists($(this),'processaction') &&
                getDataProp($(this),'processaction') == 'reclickSelf' &&
                dataPropExists($(this),'mayContinue') &&
                getDataProp($(this),'mayContinue')
            ) {
                return;
            }

            if (dataPropExists($(this),'preventdefault') && $(this).data('preventdefault')) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            showConfirmModal($(this));
        });

    } else {

        $(e).off("click",  "**");
        $(e).on("click", function (event) {

            if (dataPropExists($(this),'processaction') &&
                getDataProp($(this),'processaction') == 'reclickSelf' &&
                dataPropExists($(this),'mayContinue') &&
                getDataProp($(this),'mayContinue')
            ) {
                return;
            }

            if (dataPropExists($(this),'preventdefault') && $(this).data('preventdefault')) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            showConfirmModal($(this));
        });

    }

}

$(document).ready(function() {
    initShowConfirmModal();
});