;

function runEditInModal(el) {

    var self = el;


    var ops = {
        backdrop : 'true',
        keyboard : 'true',
        modalClass : '',
        modalTitle : '',
        modalCloseButtonLabel : 'close',
        modalBody : '',
        modalCancelButtonClass : '',
        modalCancelButtonLabel : 'cancel',
        modalOkButtonClass : '',
        modalOkButtonLabel : 'ok',
        modalcontenturl : '',
        hasfileinform : false,
        hasrandomNumberValidation : false,
        hasrandomNumberValidationText : ''
    };

    if (dataPropExists(self,'loadmodalcontentfrom')) {
        switch(getDataProp(self, 'loadmodalcontentfrom')) {
            case 'href':
                ops.modalcontenturl = self.attr('href');
                break;
            default:
                ops.modalcontenturl = self.data('loadmodalcontentfrom');
                break;
        }
    }

    if (dataPropExists(self,'modalbackdrop') && in_array(self.data('modalbackdrop'),[ true, false, 'static' ])) {
        ops.backdrop = self.data('modalbackdrop');
    }

    if (dataPropExists(self,'modalkeyboard') && in_array(self.data('modalkeyboard'),[ true, false ])) {
        ops.keyboard = self.data('modalkeyboard');
    }

    if (dataPropExists(self,'modalclass')) {
        ops.modalClass = self.data('modalclass');
    }
    if (dataPropExists(self,'hasrandomnumbervalidation') && self.data('hasrandomnumbervalidation')) {
        ops.hasrandomNumberValidation = self.data('hasrandomnumbervalidation');
    }
    if (dataPropExists(self,'hasrandomnumbervalidationtext')) {
        ops.hasrandomNumberValidationText = self.data('hasrandomnumbervalidationtext');
    }
    if (dataPropExists(self,'hasfileinform') && self.data('hasfileinform')) {
        ops.hasfileinform = self.data('hasfileinform');
    }
    if (dataPropExists(self,'modaltitle') && strlen(self.data('modaltitle'))>0) {
        ops.modalTitle = self.data('modaltitle');
    }
    if (dataPropExists(self,'modalbody') && strlen(self.data('modalbody'))>0) {
        ops.modalBody = self.data('modalbody');
    }
    if (dataPropExists(self,'modalclosebuttonlabel') && strlen(self.data('modalclosebuttonlabel'))>0) {
        ops.modalCloseButtonLabel = self.data('modalclosebuttonlabel');
    }
    if (dataPropExists(self,'modalcancelbuttonclass') && strlen(self.data('modalcancelbuttonclass'))>0) {
        ops.modalCancelButtonClass = self.data('modalcancelbuttonclass');
    }
    if (dataPropExists(self,'modalcancelbuttonlabel') && strlen(self.data('modalcancelbuttonlabel'))>0) {
        ops.modalCancelButtonLabel = self.data('modalcancelbuttonlabel');
    }
    if (dataPropExists(self,'modalokbuttonclass') && strlen(self.data('modalokbuttonclass'))>0) {
        ops.modalOkButtonClass = self.data('modalokbuttonclass');
    }
    if (dataPropExists(self,'modalokbuttonlabel') && strlen(self.data('modalokbuttonlabel'))>0) {
        ops.modalOkButtonLabel = self.data('modalokbuttonlabel');
    }

    if (elementExists('#dynamicFormModal')) {
        $('#dynamicFormModal').remove();
    }

    var modal = '<div class="modal fade" id="dynamicFormModal" ' +
        'data-backdrop="' + ops.backdrop + '" ' +
        'data-keyboard="' + ops.keyboard + '" ' +
        'data-hasrandomnumbervalidation="' + ops.hasrandomNumberValidation + '" ' +
        'data-hasfileinform="' + ops.hasfileinform + '" ' +
        'data-contenturl="' + ops.modalcontenturl + '" ' +
        ' role="dialog" aria-labelledby="dynamicFormModalTitle" aria-hidden="true">' +
        '        <div class="modal-dialog ' + ops.modalClass + '" role="document">' +
        '            <div class="modal-content">';

    if (strlen(ops.modalTitle)>0) {
        modal += '<div class="modal-header">' +
            '<h5 class="modal-title" id="dynamicFormModalTitle">' + ops.modalTitle + '</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="' + ops.modalCloseButtonLabel + '"><span aria-hidden="true"></span></button>' +
            '</div>';
    }

    modal += '<div class="modal-body"><div>' + ops.modalBody + '</div><div class="mt-2 d-none respMsg"></div></div>' +
        '<div class="modal-footer justify-content-between">' +
        '<button type="button" class="btn btn-secondary ' + ops.modalCancelButtonClass + '" data-dismiss="modal" title="' + ops.modalCancelButtonLabel + '">' + ops.modalCancelButtonLabel + '</button>';

    if(ops.hasrandomNumberValidation) {
        modal += '<div class="form-inline"><span><span class="form-control-feedback">' + ops.hasrandomNumberValidationText + '<span class="_randNumForValidation ml-2">' + getRandomInt(100,999) + '</span><input name="_randNumForValidation" class="form-control ml-2 mr-2" style="max-width: 4rem;" /></span></span>';
    }

    modal +=        '<button type="button" class="btn btn-primary ' + ops.modalOkButtonClass + ' _process" title="' + ops.modalOkButtonLabel + '">' + ops.modalOkButtonLabel + '</button>';

    if(ops.hasrandomNumberValidation) {
        modal += '</div>';
    }

    modal +=    '</div>' +
        '</div>' +
        '</div>' +
        '</div>';




    $('body').append($(modal));

    $('#dynamicFormModal').on('hide.bs.modal', function (e) {
        $('#dynamicFormModal .modal-body > div').empty();
        $('#dynamicFormModal .respMsg').html('').removeClass('d-flex justify-content-center').addClass('d-none');
        $("#dynamicFormModal ._process").show();
        config.userInteractionStarted = false;
    });

    $('#dynamicFormModal').on('shown.bs.modal', function (e) {
        config.userInteractionStarted = true;
        destroyToolTips('[data-toggle="tooltip"]');


        if (dataPropExists('#dynamicFormModal','contenturl')) {

            var url = $('#dynamicFormModal').data('contenturl');
            var msgBox = $('#dynamicFormModal .respMsg');

            msgBox.html(loader).removeClass('d-none').addClass('d-flex justify-content-center');

            _request(url, 'get', {}, function(r){

                if (r.status) {
                    if (isset(r.html_replaceOriginal) && r.html_replaceOriginal && isset(r.html) && strlen(r.html)>0) {
                        $('#dynamicFormModal .modal-body > div:not(.respMsg)').html(r.html);

                        initSelect2();
                        initTabbed('.tabbed');

                        if (elementExists('#dynamicFormModal .ckEditor')) {
                            initCK('#dynamicFormModal .ckEditor');
                        }

                    }
                }

                if (strlen(r.msg)>0) {
                    msgBox.removeClass('d-none d-flex justify-content-center');
                    msgBox.html(wrapResponse(r.msg, ((r.status) ? 'success' : 'error' ) ));
                } else {
                    msgBox.html('');
                    msgBox.addClass('d-none');
                }

            }, {});
        }


    });


    $('#dynamicFormModal').modal('show');

}

function initEditInModal() {

    $("._editInModal").off("click",  "**");
    $("._editInModal").on("click",  function (ev) {
        var self = $(this);
        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();


        if (dataPropExists(self,'preventdefault') && getDataProp(self,'preventdefault')) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
        }
        runEditInModal(self);

    });

    $("body").off("click", "._editInModalBinded", "**");
    $("body").on("click", "._editInModalBinded", function (ev) {
        var self = $(this);

        if (dataPropExists(self,'preventdefault') && getDataProp(self,'preventdefault')) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
        }
        runEditInModal(self);
    });


    $("body").off("click", "#dynamicFormModal ._process", "**");
    $("body").on("click", "#dynamicFormModal ._process", function () {
        var self = $(this);
        var form = $('#dynamicFormModal').find('form');
        var formParent = form.parent();
        var msgBox = $('#dynamicFormModal .respMsg');
        var url = form.attr('action');
        var method = form.attr('method');

        if (dataPropExists('#dynamicFormModal','hasrandomnumbervalidation') &&
            $('#dynamicFormModal').data('hasrandomnumbervalidation') &&
            elementExists($('#dynamicFormModal ._randNumForValidation')) &&
            strlen($('#dynamicFormModal ._randNumForValidation').html())>0 &&
            elementExists($('#dynamicFormModal [name="_randNumForValidation"]'))
        ) {
            var randNumberInputContainer = $('#dynamicFormModal [name="_randNumForValidation"]').closest('.form-inline');
            randNumberInputContainer.children('span').removeClass('has-error');

            if ($('#dynamicFormModal ._randNumForValidation').html() != $('#dynamicFormModal [name="_randNumForValidation"]').val()) {
                randNumberInputContainer.children('span').addClass('has-error');
                return;
            }
        }

        //self.hide();
        msgBox.html(loader).removeClass('d-none').addClass('d-flex justify-content-center');

        if ($('#dynamicFormModal').find('.ckEditor')) {

            $('#dynamicFormModal .ckEditor').each(function () {
                for (var instanceName in CKEDITOR.instances) {
                    CKEDITOR.instances[instanceName].updateElement();
                }
            });
        }

        var formData = form.serialize();
        var requestOps = {};

        if (dataPropExists('#dynamicFormModal','hasfileinform') && $('#dynamicFormModal').data('hasfileinform')) {
            formData = new FormData( document.querySelector( "#dynamicFormModal form" ) );
            requestOps = {  processData: false, contentType: false, cache: false };
        }

        _request(url, method, formData, function(r) {

            msgBox.removeClass('d-none d-flex justify-content-center');

            msgBox.html(wrapResponse(r.msg, ((r.status) ? 'success' : 'error' ) ));

            if (isset(r.html) && isset(r.html_replaceOriginal) && r.html_replaceOriginal) {
                formParent.empty().html(r.html);
            }

            initSelect2();

            if (elementExists('#dynamicFormModal .ckEditor')) {
                initCK('#dynamicFormModal .ckEditor');
            }

            if (r.status) {
                //$('#dynamicFormModal').modal('show');
                showLoader();
                if (isset(r.redirect) && r.redirect && isset(r.redirect_url) && strlen(r.redirect_url)>0) {
                    loadUrl(r.redirect_url);
                } else if (isset(r.callJsFunction && strlen(r.callJsFunction)>0)) {
                    eval(r.callJsFunction);
                } else if (isset(r.closeModal) && r.closeModal) {
                    $('#dynamicFormModal').modal('hide');
                } else {
                    reloadUrl();
                }

            } else {
                if (elementExists($('#dynamicFormModal [name="_randNumForValidation"]')) && elementExists($('#dynamicFormModal ._randNumForValidation'))) {
                    $('#dynamicFormModal [name="_randNumForValidation"]').val('');
                    $('#dynamicFormModal ._randNumForValidation').html(getRandomInt(100,999));
                }
            }

            hideLoader();

            self.show();

        }, requestOps );

    });
}

$(document).ready(function() {

    initEditInModal();


});