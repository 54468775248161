;
$(document).ready(function() {

    $('#passwordChangePopupModal').on('show.bs.modal', function (e) {
        $('#passwordChangePopupModal').find('form')[0].reset();
        $('#passwordChangePopupModal .respMsg').html('').removeClass('d-flex justify-content-center').addClass('d-none');

        $('#passwordChangePopupModal').find('div').each(function(){ $(this).removeClass('has-error'); });
        $('#passwordChangePopupModal').find('div.form-control-feedback').each(function(){ $(this).empty(); });

        $("#passwordChangePopupModal ._process").show();
    });

    $("body").off("click", "._openChangePassModal", "**");
    $("body").on("click", "._openChangePassModal", function () {
        $('#passwordChangePopupModal').modal('show');
    });

    $("body").off("click", "#passwordChangePopupModal ._process", "**");
    $("body").on("click", "#passwordChangePopupModal ._process", function () {
        var self = $(this);
        var form = $('#passwordChangePopupModal').find('form');
        var formParent = form.parent();
        var msgBox = $('#passwordChangePopupModal .respMsg');
        var url = form.attr('action');
        var method = form.attr('method');

        self.hide();
        msgBox.html(loader).removeClass('d-none').addClass('d-flex justify-content-center');

        _request(url, method, form.serialize(), function(r) {

            msgBox.removeClass('d-none d-flex justify-content-center');

            msgBox.html(wrapResponse(r.msg, ((r.status) ? 'success' : 'error' ) ));

            if (isset(r.html) && isset(r.html_replaceOriginal) && r.html_replaceOriginal) {
                formParent.empty().html(r.html);
            }

            self.show();

        }, {  });

    });



});