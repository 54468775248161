;
$(document).ready(function() {


    $("body").off("click", "._generateSlug", "**");
    $("body").on("click", "._generateSlug", function () {

        $('._slug').each(function(){
            var self = $(this);

            if (dataPropExists(self, 'slugsource') && elementExists($('[' + self.data('slugsource') + ']'))) {
                var sourceStr = $('[' + self.data('slugsource') + ']').val();

                if (strlen(sourceStr)<1) sourceStr = generateUUID();

                self.val(url_slug(sourceStr));

            }
        });

    });
});