;
$(document).ready(function() {

    $("body").off("click", "._getContentInModal", "**");
    $("body").on("click", "._getContentInModal", function (e) {
        var self = $(this);

        if (dataPropExists(self,'preventdefault') && getDataProp(self,'preventdefault')) {
            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }

        var ops = {
            backdrop : 'true',
            keyboard : 'true',
            modalClass : '',
            modalTitle : '',
            modalBody : '',
            modalCloseButtonLabel : 'close',
            modalCloseButtonClass : '',
            modalcontenturl : '',
            modalhasfooter : false,
            modalloadmethod : 'get'
        };

        if (dataPropExists(self,'modalbackdrop') && in_array(self.data('modalbackdrop'),[ true, false, 'static' ])) {
            ops.backdrop = self.data('modalbackdrop');
        }
        if (dataPropExists(self,'modalkeyboard') && in_array(self.data('modalkeyboard'),[ true, false ])) {
            ops.keyboard = self.data('modalkeyboard');
        }
        if (dataPropExists(self,'modalclass')) {
            ops.modalClass = self.data('modalclass');
        }
        if (dataPropExists(self,'modaltitle') && strlen(self.data('modaltitle'))>0) {
            ops.modalTitle = self.data('modaltitle');
        }
        if (dataPropExists(self,'modalbody') && strlen(self.data('modalbody'))>0) {
            ops.modalBody = self.data('modalbody');
        }
        if (dataPropExists(self,'modalclosebuttonlabel') && strlen(self.data('modalclosebuttonlabel'))>0) {
            ops.modalCloseButtonLabel = self.data('modalclosebuttonlabel');
        }
        if (dataPropExists(self,'modalclosebuttonclass') && strlen(self.data('modalclosebuttonclass'))>0) {
            ops.modalCloseButtonClass = self.data('modalclosebuttonclass');
        }
        if (dataPropExists(self,'loadmodalcontentfrom')) {
            switch(getDataProp(self, 'loadmodalcontentfrom')) {
                case 'href':
                    ops.modalcontenturl = self.attr('href');
                    break;
                default:
                    ops.modalcontenturl = self.data('loadmodalcontentfrom');
                    break;
            }
        }
        if (dataPropExists(self,'modalhasfooter') && strlen(self.data('modalhasfooter'))>0) {
            ops.modalhasfooter = self.data('modalhasfooter');
        }

        if (elementExists('#dynamicContentModal')) {
            $('#dynamicContentModal').remove();
        }

        var modal = '<div class="modal fade" id="dynamicContentModal" ' +
            'data-backdrop="' + ops.backdrop + '" ' +
            'data-keyboard="' + ops.keyboard + '" ' +
            'data-contenturl="' + ops.modalcontenturl + '" ' +
            ' data-method="' + ops.modalloadmethod + '" ' +
            ' role="dialog" aria-labelledby="dynamicContentModalTitle" aria-hidden="true">' +
            '        <div class="modal-dialog ' + ops.modalClass + '" role="document">' +
            '            <div class="modal-content">';

        if (strlen(ops.modalTitle)>0) {
            modal += '<div class="modal-header">' +
                '<h5 class="modal-title" id="dynamicContentModalTitle">' + ops.modalTitle + '</h5>' +
                '<button type="button" class="close" data-dismiss="modal" aria-label="' + ops.modalCloseButtonLabel + '"><span aria-hidden="true"></span></button>' +
                '</div>';
        }

        modal += '<div class="modal-body">' + ops.modalBody + '</div>';

        if (ops.modalhasfooter) {
            modal += '<div class="modal-footer justify-content-center">' +
            '<button type="button" class="btn btn-primary ' + ops.modalCloseButtonClass + '" data-dismiss="modal" title="' + ops.modalCloseButtonLabel + '">' + ops.modalCloseButtonLabel + '</button>' +
            '</div>';
        }

        modal += '</div>' +
            '</div>' +
            '</div>';

        $('body').append($(modal));

        $('#dynamicContentModal').on('hide.bs.modal', function (e) {
            $('#dynamicContentModal .modal-body').empty();
            config.userInteractionStarted = false;
        });

        $('#dynamicContentModal').on('shown.bs.modal', function (e) {
            config.userInteractionStarted = true;
            destroyToolTips('[data-toggle="tooltip"]');

            if (dataPropExists('#dynamicContentModal','contenturl')) {

                var url = $('#dynamicContentModal').data('contenturl');
                var method = $('#dynamicContentModal').data('method');
                var body = $('#dynamicContentModal .modal-body');

                body.html(loader).removeClass('d-none').addClass('d-flex justify-content-center');

                _request(url, method, {}, function(r){

                    if (r.status && isset(r.html_replaceOriginal) && r.html_replaceOriginal && isset(r.html) && strlen(r.html)>0) {
                        body.html(r.html).removeClass('d-flex justify-content-center');

                        populateImages('.img-covered');
                        initTabbed('.tabbed');
                        initTooltip('[data-toggle="tooltip"]');

                    } else {
                        body.html(wrapResponse(r.msg,'error')).removeClass('d-flex justify-content-center');
                    }

                }, {});
            }


        });


        $('#dynamicContentModal').modal('show');

    });

});