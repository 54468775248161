;
$(document).ready(() => {

    $('body').off('change', 'input[type="checkbox"].__vichImageFakeDeleteChBox', '**');
    $('body').on('change', 'input[type="checkbox"].__vichImageFakeDeleteChBox', function () {

        var status = $(this).is(':checked');

        if (dataPropExists($(this), 'vichimagefieldscontainer') && elementExists($(this).data('vichimagefieldscontainer'))) {

            var chBox = $($(this).data('vichimagefieldscontainer')).find('input[type="checkbox"]');
            chBox.prop('checked', status);

        }

    });

    $('body').off('click', '.__uploadAvatarButton', '**');
    $('body').on('click', '.__uploadAvatarButton', function () {

        if (dataPropExists($(this), 'vichimagefieldscontainer') && elementExists($(this).data('vichimagefieldscontainer'))) {

            var fileInput = $($(this).data('vichimagefieldscontainer')).find('input[type="file"]');
            fileInput.trigger('click');

        }

    });

});
