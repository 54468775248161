;
$(document).ready(function() {

    enquire.register("screen and (max-width:1200px)", {

        setup : function() {

        },

        match : function() {

        },

        unmatch : function() {

        },

        destroy : function() {}

    }).register("screen and (max-width:991px)", {

        setup : function() {

        },

        match : function() {

            $('<div class="nanoScroller __topMenuMobileContainer"><div class="nano-content"></div></div>').prependTo('body');
            $('ul[data-menu="__topMenu"]').detach().appendTo('.__topMenuMobileContainer .nano-content');

            var $topButtons = $();

            if (elementExists('.userBar .buttons')) {
                $('.userBar .buttons').each(function(index){
                    $topButtons = $topButtons.add($(this).detach());
                });

                $('.__topMenuMobileContainer .nano-content').prepend($topButtons);
            }

            if ( $('body').hasClass('__userLoggedIn')) { // be van lepve

                $('.__topMenuMobileContainer .nano-content').prepend($('<div class="__mobile_loggedUserTopData"></div>'));

                if (elementExists('.loggedUserTopData .avatar')) {
                    $('.__mobile_loggedUserTopData').prepend($('.loggedUserTopData .avatar').detach());
                }

                if (elementExists('.loggedUserTopData .loggedUserName')) {
                    $('.__mobile_loggedUserTopData').append($('.loggedUserTopData .loggedUserName').html());
                }

            }

            if (!elementExists('.__topMenuMobileOverlay')) {
                $('body').prepend($('<div class="__topMenuMobileOverlay"></div>'));
            }

            $('nav.mainNavContainer').hide();

        },

        unmatch : function() {
            $('ul[data-menu="__topMenu"]').detach().appendTo('nav.mainNavContainer');

            var $topButtons = $();

            if (elementExists('.nano-content .buttons')) {
                $('.nano-content .buttons').each(function(index){
                    $topButtons = $topButtons.add($(this).detach());
                });

                $('header .userBar').append($topButtons);

                $('.nano-content .buttons').remove();
            }

            if (elementExists('.__mobile_loggedUserTopData .avatar') && elementExists('header .loggedUserTopData')) {
                $('.__mobile_loggedUserTopData .avatar').detach().appendTo($('header .loggedUserTopData'));
                $('.nano-content .__mobile_loggedUserTopData').remove();
            }

            $('.topMenuContainer ul.shownOnMobile').removeClass('shownOnMobile');
            if (elementExists('.__topMenuMobileOverlay')) {
                $('.__topMenuMobileOverlay').remove();
            }
            if (elementExists('.__topMenuMobileContainer')) {
                $('.__topMenuMobileContainer').remove();
            }

            $('nav.mainNavContainer').show();
        },

        destroy : function() {}

    }).register("screen and (max-width:768px)", {
        setup : function() {

        },

        match : function() {

            $('.tabbed').each(function(){
                $(this).addClass('accordion');

                $(this).find("ul:first-of-type > li > a").each(function(){
                    $('[data-tabcontent-id="'+$(this).data('tabId')+'"]').detach().appendTo($(this).parent());

                    //$('#' + $(this).data('tabId')).detach().appendTo($(this).parent());
                });
            });

        },

        unmatch : function() {

            $('.tabbed').each(function(){
                var _container = $(this);

                $(this).find("ul:first-of-type > li > div").each(function(){
                    $(this).detach().appendTo(_container);
                });

                $(this).removeClass('accordion');
            });

        },

        destroy : function() {}

    }).register("screen and (max-width:470px)", {

        setup : function() {
        },

        match : function() {

        },

        unmatch : function() {

        },

        destroy : function() {}

    });


});