;
$(document).ready(function() {

   // $.fn.dataTable.ext.classes.sLengthSelect = 'form-control form-control-sm select2';

    populateImages('.img-covered');
    initTopMenu();
    initDatePicker();
    initDateRangePicker('._dateRangePicker');
    initSelect2();
    initCK('.ckEditor');
    initTooltip('[data-toggle="tooltip"]');
    initSymfonyCollections('._symfonyCollection');
    initjScrollPane('.withScoll');
    initShowOnDocReady('._showOnlyOnDocReady');
    initMaxLenght('[maxlength]');
    initTabbed('.tabbed');
    generateObfuscatedEmailLinks('.obfM');
    generateObfuscatedPhoneLinks('.obfP');
    checkCheckboxOnEvent('[data-checkcheckbox]');
    initFancyBox('[data-fancybox]');
    initSwitchGroup('._switchContentBasedOnValue');
    initPrintElement('._printElement');
    initOverlayScrollbars('._overlayScrollbars');

});

window.addEventListener("load", function(){
    initCookieConsent('.__cookieConsentInitElement');

});