;
$(document).ready(function() {



    $("body").off("change", "[data-switchgroupname]", "**");
    $("body").on("change", "[data-switchgroupname]", function () {
        var self = $(this);

        if ( dataPropExists(self,'value') &&
            elementExists('[data-switchgrouptargetname="' + getDataProp(self,'switchgroupname') + '"]') &&
            elementExists('[data-switchgroupelementname="' + getDataProp(self,'value')+ '"]')
        ) {
            /*            $('[data-switchgrouptargetname="' + getDataProp(self,'switchgroupname') + '"] > [data-switchgroupelementname]').hide();
                        $('[data-switchgrouptargetname="' + getDataProp(self,'switchgroupname') + '"] > [data-switchgroupelementname="' + getDataProp(self,'value')+ '"]').show();*/

            $('[data-switchgrouptargetname="' + getDataProp(self,'switchgroupname') + '"]').find('[data-switchgroupelementname]').each(function(){ $(this).addClass('d-none'); });
            $('[data-switchgrouptargetname="' + getDataProp(self,'switchgroupname') + '"]').find('[data-switchgroupelementname="' + getDataProp(self,'value') + '"]').each(function(){ $(this).removeClass('d-none').show(); });

        }

    });

});