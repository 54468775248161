;
function setELfinderValue(value, element_id) {

    if ( window.selectedElfEl ) {
        $('[data-elf-input-id="' + window.selectedElfEl + '"]').val(value).trigger('change');
        window.selectedElfEl = null;
    }

}


function addBackgroundItemFile(file,parent,token,url) {
    showLoader();

    _request(url, 'POST', { '_token' : token , 'img' : file }, function(r) {
        reloadUrl();
        hideLoader();
    });
}

$(document).ready(function() {

    $("body").off("click", '[data-type="elfinder-input-field"]', "**");
    $("body").on("click", '[data-type="elfinder-input-field"]', function (event) {
        var self = $(this);

        if (dataPropExists(self, 'elfInputId') && dataPropExists(self, 'elfPath') && elementExists('[data-elf-input-id="' + self.data('elfInputId') + '"]')) {
            window.selectedElfEl = self.data('elfInputId');
            var childWin = window.open(self.data('elfPath'), "popupWindow", "height=450, width=900");
        }

    });


    $("body").off("click", '._triggerElfinder', "**");
    $("body").on("click", '._triggerElfinder', function (event) {
        var self = $(this);

        if (dataPropExists(self, 'target') && elementExists(self.data('target'))) {
            $(self.data('target')).trigger('click');
        }

    });

    $("body").off("change", '[data-elfinderinstance]', "**");
    $("body").on("change", '[data-elfinderinstance]', function (event) {
        var self = $(this);

        if (dataPropExists(self, 'callbackname') && typeof self.data('callbackname') === "string" && dataPropExists(self,'parentid') && dataPropExists(self,'token') && dataPropExists(self,'url')) {

            var f = eval(self.data('callbackname'));
            if (typeof f == 'function') {
                f(self.val(), self.data('parentid'), self.data('token'), self.data('url'));
            }
        }

    });


});
