;
var loader = '<span class="spinner"><span class="rect1"></span><span class="rect2"></span><span class="rect3"></span><span class="rect4"></span><span class="rect5"></span></span>';
var loaderWhite = '<span class="spinner spinner-white"><span class="rect1"></span><span class="rect2"></span><span class="rect3"></span><span class="rect4"></span><span class="rect5"></span></span>';
var fullScreenLoaderHtml = '<div class="fullScreenLoader"><div class="spinner spinner-dark"><span class="rect1"></span><span class="rect2"></span><span class="rect3"></span><span class="rect4"></span><span class="rect5"></span></div></div>';

var config = {};
config.debug = true;
config.validImageTypes = ["image/gif", "image/jpeg", "image/png"];
config.validFileTypes = ["image/gif", "image/jpeg", "image/png"];
config.windowResizeEnd = false;
config.locale = $('html').data('locale');
config.notifyModalTimeout = 4000;
config.dropZoneAcceptedFiles = 'image/jpeg,image/gif,image/png,image/bmp,image/webp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation,application/pdf';
config.maxFilesize = 2;

config.dashReloadTime = 5 * 60 * 1000;
config.userInteractionStarted = false;

Highcharts.setOptions({
    colors: [ '#ffffff', '#139434', '#136194', '#ff9900' ]
});

config.DT = {
    dom : {
        default :   "<'row d-flex align-items-center mb-2'" +
                        "<'col-sm-12 col-md-6 mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start'l>" +
                        "<'col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'f>" +
                    ">" +
                    "<'row'" +
                        "<'col-sm-12'<'table-responsive'tr>>" +
                    ">" +
                    "<'row mt-2" +
                        "'<'col-sm-12 col-md-5'i>" +
                        "<'col-sm-12 col-md-7 mt-2 mt-md-0'p>" +
                    ">",

        buttons :   "<'row d-flex align-items-center mb-2'" +
                    "<'col-sm-12 col-md-6 mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start'l>" +
                    "<'col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'f>" +
                    ">" +
                    "<'row d-flex align-items-center'" +
                    "<'col-12 mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start'B>" +
                    ">" +
                    "<'row'" +
                    "<'col-sm-12'<'table-responsive'tr>>" +
                    ">" +
                    "<'row mt-2" +
                    "'<'col-sm-12 col-md-5'i>" +
                    "<'col-sm-12 col-md-7 mt-2 mt-md-0'p>" +
                    ">",
        buttonsNoLenght : "<'row d-flex align-items-center mb-2'" +
                            "<'col-sm-12 col-md-6 mb-2 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-start'B>" +
                            "<'col-sm-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'f>" +
                            ">" +
                            "<'row'" +
                            "<'col-sm-12'<'table-responsive'tr>>" +
                            ">" +
                            "<'row mt-2" +
                            "'<'col-sm-12 col-md-5'i>" +
                            "<'col-sm-12 col-md-7 mt-2 mt-md-0'p>" +
                            ">",
        minimal :   "<'row d-flex align-items-center mb-2'" +
                    "<'col-sm-12'<'table-responsive'tr>>" +
                    ">"

    }
};

config.DT_user = {
    dom : config.DT.dom.minimal,
    autoWidth: false,
    language: {
        url: '/static/js/datatables_i18n/' + $('html').attr('lang') + '.json'
    }
};

var VoidCMS_CKEditor_dynamicTags_allData = {
        '[teljesNev]' : 'a felhasználó teljes neve'
    };

var ckEditorConfig = {

    initiated : false,

    stylesSets : {
        minimal : [

        ],
        medium : [
            { name: 'Main title', element: 'h3', styles: {  } },
            { name: 'Subtitle', element: 'h4', styles: {  } },
            { name: 'Subtitle - level 2', element: 'h5', styles: {  } },
            { name: 'Paragraph', element: 'p', styles: {  } }
        ],
        full : [
            { name: 'Main title', element: 'h3', styles: {  } },
            { name: 'Subtitle', element: 'h4', styles: {  } },
            { name: 'Subtitle - level 2', element: 'h5', styles: {  } },
            { name: 'Paragraph', element: 'p', styles: {  } }
        ]

    },

    toolbars : {
        minimal : [
            //{ name: 'document', items: [ 'Source' ] },
            { name: 'basicstyles', items: [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList' ] }
        ],
        medium : [
            { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            { name: 'editing', items: [ 'Find', 'Replace', 'SelectAll' ] },
            '/',
            { name: 'styles', items: [ 'Styles' ] },
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Subscript', 'Superscript' ] },
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
            { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
            { name: 'insert', items: [ 'Table', 'HorizontalRule' ] }


        ],
        full : [
            { name: 'clipboard', items: [ 'Source', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            { name: 'editing', items: [ 'Find', 'Replace', 'SelectAll' ] },
            { name: 'voidCMSButtons', items: [ 'DynamicTags' ] },
            '/',
            { name: 'styles', items: [ 'Styles' ] },
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Subscript', 'Superscript' ] },
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
            { name: 'links', items: [ 'Image', 'Link', 'Unlink', 'Anchor' ] },
            { name: 'insert', items: [ 'Table', 'HorizontalRule' ] }


        ]
    }
};

/*,
{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
{ name: 'links', groups: [ 'links' ] },
{ name: 'insert', groups: [ 'insert' ] }*/


ckEditorConfig.minimalCfg = {
    height  :  '200px',
    defaultLanguage : $('html').attr('lang'),
    language  : $('html').attr('lang'),
    //format_tags: 'p;b;em;strong;ul;ol;li;',
    fillEmptyBlocks : false,

    allowedContent: 'p b em strong ul ol li',
    entities : false,
    autoParagraph : true,
    stylesSet : 'minimal',
    toolbar : ckEditorConfig.toolbars.minimal,

    extraPlugins : 'wordcount,htmlwriter,notification',
    //magicline_everywhere : true,
    removePlugins : 'elementspath',

    wordcount : {
        showParagraphs: false,
        showWordCount: false,
        showCharCount: true,
        countSpacesAsChars: true,
        countHTML: false,
        maxWordCount: -1,
        maxCharCount: -1
    },

    enterMode : 1

};
ckEditorConfig.mediumCfg = {
    height  :  '400px',
    defaultLanguage : $('html').attr('lang'),
    language  : $('html').attr('lang'),
    //format_tags: 'p;b;em;strong;ul;ol;li;h2;h3',
    fillEmptyBlocks : false,
    allowedContent : true,
    extraAllowedContent: 'div[class] img[class] a[class] p[class] b em strong ul[class] ol li h2[class] h3[class] h4[class] h5[class]',
    entities : false,
    autoParagraph : true,
    //magicline_everywhere : true,
    toolbar : ckEditorConfig.toolbars.medium,


    stylesSet : 'medium',

    wordcount : {
        showParagraphs: false,
        showWordCount: false,
        showCharCount: true,
        countSpacesAsChars: true,
        countHTML: false,
        maxWordCount: -1,
        maxCharCount: -1
    },

    enterMode : 1

};
ckEditorConfig.fullCfg = {
    defaultLanguage : $('html').attr('lang'),
    language  : $('html').attr('lang'),
    height  :  '400px',
    //format_tags: 'p;h2;h3',
    fillEmptyBlocks : false,
    allowedContent : true,

    extraAllowedContent: 'div[class] img[class] a[class] p[class] b em strong ul[class] ol li h2[class] h3[class] h4[class] h5[class]',
    //allowedContent: 'p b em strong ul ol li',
    entities : false,
    //magicline_everywhere : true,
    toolbar : ckEditorConfig.toolbars.full,
    customConfig: '/static/js/ckeditor_config/config.js',
    extraPlugins: 'codemirror,dynamictags',
    autoParagraph : false,

    stylesSet : 'full',

    wordcount : {
        showParagraphs: false,
        showWordCount: false,
        showCharCount: true,
        countSpacesAsChars: true,
        countHTML: false,
        maxWordCount: -1,
        maxCharCount: -1
    },

    enterMode : 1

};

var hasLS = (function () {
    var mod = 'test_ls';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch(e) {
        return false;
    }
}());


moment.locale(config.locale);