;
$(document).ready(function() {

    if (elementExists('.vegasBgOverlayPreviewBox')) {
        $('.vegasBgOverlayPreviewBox').find('.form-check').each(function(){
            var self = $(this);
            var i = self.find('input');
            var l = self.find('label');

            if (strlen(i.val())>1) {
                l.css('backgroundImage', 'url(/static/img/vegas/' + i.val() + ')');
            }
        });
    }

});