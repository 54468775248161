;
function cc_enableCookies() {
    cl('cookies enabled');
}
function cc_disableCookies() {
    cl('cookies disabled');
}

function initOverlayScrollbars(e) {
    if (elementExists(e)) {
        $(e).overlayScrollbars({ className : 'os-theme-light' });
    }
}

function initFancyBox(e) {
    if (elementExists(e)) {
        $(e).fancybox({
            loop: true,
            protect: true,
            buttons: [
                "zoom",
                //"share",
                //"slideShow",
                //"fullScreen",
                //"download",
                "thumbs",
                "close"
            ]
        });
    }
}

function generateUUID(){
    var d = new Date().getTime();
    if(window.performance && typeof window.performance.now === "function"){
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
}
function initPrintElement(e) {
    $("body").off("*", e, "**");
    $("body").on("click", e, function () {
        event.preventDefault();
        event.stopPropagation();
        var self = $(this);

        if (dataPropExists(self,'elementtoprint') && elementExists(self.data('elementtoprint'))) {
            var element = self.data('elementtoprint');
            var style = false;
            var opt = {};

            if (dataPropExists(self,'printstylesheeturl') && strlen(self.data('printstylesheeturl')) > 0 ) {
                style = self.data('printstylesheeturl');
            }
            if (dataPropExists(self,'append') && strlen(self.data('append')) > 0 ) {
                opt['append'] = self.data('append');
            }
            if (dataPropExists(self,'prepend') && strlen(self.data('prepend')) > 0 ) {
                opt['prepend'] = self.data('prepend');
            }

            $(element).print(opt);
        }

        return;
    });
}

function initSwitchGroup(e) {
    if (elementExists(e)) {
        $(e).each(function(){
            var self = $(this);

            self.find('[data-switchgroupname]').each(function(){
                var _tmp = $(this);

                if ( dataPropExists(_tmp,'value') &&
                    elementExists('[data-switchgrouptargetname="' + getDataProp(_tmp,'switchgroupname') + '"]') &&
                    elementExists('[data-switchgroupelementname="' + getDataProp(_tmp,'value') + '"]')
                ) {
                    $('[data-switchgrouptargetname="' + getDataProp(_tmp,'switchgroupname') + '"]').find('[data-switchgroupelementname]').each(function(){ $(this).addClass('d-none').hide(); });


                    /*if (_tmp.is(':checked')) {
                        $('[data-switchgrouptargetname="' + getDataProp(_tmp,'switchgroupname') + '"] > [data-switchgroupelementname="' + getDataProp(_tmp,'value')+ '"]').show();
                    }*/
                }

            });

            self.find('[data-switchgroupname]:checked').each(function(){
                var _tmp = $(this);

                if ( dataPropExists(_tmp,'value') &&
                    elementExists('[data-switchgrouptargetname="' + getDataProp(_tmp,'switchgroupname') + '"]') &&
                    elementExists('[data-switchgroupelementname="' + getDataProp(_tmp,'value') + '"]')
                ) {
                    $('[data-switchgrouptargetname="' + getDataProp(_tmp,'switchgroupname') + '"]').find('[data-switchgroupelementname="'+ getDataProp(_tmp,'value') +'"]').each(function(){ $(this).removeClass('d-none').show(); });
                }

            });


        });
    }
}

function checkCheckboxOnEvent(e) {

    if (elementExists(e) && $(e).data('checkcheckbox') == true && dataPropExists(e,'event')) {
        e = $(e);

        if (strlen(e.data('event'))>0) {

            var event = e.data('event');

            e.each(function(){
                var self = $(this);

                if (dataPropExists(self,'clickelement') && elementExists($(self.data('clickelement')))) {
                    var _name =  $('[name="' + $(self.data('clickelement')).attr('name') + '"]') ;

                    self.on(event, function (e) {
                        if (strlen(self.val())>0) {

                            $(self.data('clickelement')).attr("checked", "checked");
                            $(self.data('clickelement')).prop("checked", true);
                        } else {
                            _name.prop("checked", false);
                        }
                    });


                }

            });
        }



    }
}

function generateObfuscatedEmailLinks(e) {
    if (elementExists(e)) {
        e = $(e);
        e.each(function(){
            var self = $(this);

            if (dataPropExists(self,'domain') && dataPropExists(self,'user') && self.prop('nodeName').toLowerCase() == 'a') {

                self.attr('href',('mailto:' + strrev(self.data('user')) + '@' + strrev(self.data('domain')) ));

            }

        });

    }
}
function generateObfuscatedPhoneLinks(e) {
    if (elementExists(e)) {
        e = $(e);
        e.each(function(){
            var self = $(this);

            if (dataPropExists(self,'no') && self.prop('nodeName').toLowerCase() == 'a') {

                self.attr('href',('tel:' + strrev(self.data('no')) ));

            }

        });
    }
}

function initCookieConsent(e) {

    var ops = {
        position : null,
        type: null,
        theme: null,
        palette: {
            popup: { background: '#000000', text: '#fff', link: '#fff' },
            button: { background: 'transparent', border: '#f8e71c', text: '#f8e71c' },
            highlight: { background: '#f8e71c', border: '#f8e71c', text: '#000000' }
        },
        /*law : {
            countryCode : $('html').data('lang')
        },*/
        layouts: {
            'basic-header': '<div class="_cc-msg">{{header}}{{message}}{{link}}</div><div class="_cc-btns">{{compliance}}</div>',
        },
        layout: 'basic-header',
        revokable : false,
        revokeBtn : '<div class="cc-revoke {{classes}}">Cookie Policy</div>',
        content: {
            header: 'Cookies used on the website!',
            message: 'This website uses cookies to ensure you get the best experience on our website.',
            dismiss: 'Got it!',
            allow: 'Allow cookies',
            deny: 'Decline',
            link: 'Learn more',
            href: 'http://cookiesandyou.com',
            close: '&#x274c;'
        }
    };

    if (elementExists(e)) {
        e = $(e);

        if (dataPropExists(e,'contentHeader') && strlen(e.data('contentHeader')) > 0) { ops.content.header = e.data('contentHeader'); }
        if (dataPropExists(e,'contentMessage') && strlen(e.data('contentMessage')) > 0) { ops.content.message = e.data('contentMessage'); }
        if (dataPropExists(e,'contentDismiss') && strlen(e.data('contentDismiss')) > 0) { ops.content.dismiss = e.data('contentDismiss'); }
        if (dataPropExists(e,'contentAllow') && strlen(e.data('contentAllow')) > 0) { ops.content.allow = e.data('contentAllow'); }
        if (dataPropExists(e,'contentDeny') && strlen(e.data('contentDeny')) > 0) { ops.content.deny = e.data('contentDeny'); }
        if (dataPropExists(e,'contentClose') && strlen(e.data('contentClose')) > 0) { ops.content.close = e.data('contentClose'); }
        if (dataPropExists(e,'contentLink') && strlen(e.data('contentLink')) > 0) { ops.content.link = e.data('contentLink'); }
        if (dataPropExists(e,'contentHref') && strlen(e.data('contentHref')) > 0) { ops.content.href = e.data('contentHref'); }
        if (dataPropExists(e,'contentCookiePolicy') && strlen(e.data('contentCookiePolicy')) > 0) {
            ops.revokeBtn = '<div class="cc-revoke {{classes}}">' + e.data('contentCookiePolicy') + '</div>';
        }

        if (dataPropExists(e,'palettePopupBackground') && strlen(e.data('palettePopupBackground')) > 0) { ops.palette.popup.background = e.data('palettePopupBackground'); }
        if (dataPropExists(e,'palettePopupText') && strlen(e.data('palettePopupText')) > 0) { ops.palette.popup.text = e.data('palettePopupText'); }
        if (dataPropExists(e,'palettePopupLink') && strlen(e.data('palettePopupLink')) > 0) { ops.palette.popup.link = e.data('palettePopupLink'); }
        if (dataPropExists(e,'paletteButtonBackground') && strlen(e.data('paletteButtonBackground')) > 0) { ops.palette.button.background = e.data('paletteButtonBackground'); }
        if (dataPropExists(e,'paletteButtonBorder') && strlen(e.data('paletteButtonBorder')) > 0) { ops.palette.button.border = e.data('paletteButtonBorder'); }
        if (dataPropExists(e,'paletteButtonText') && strlen(e.data('paletteButtonText')) > 0) { ops.palette.button.text = e.data('paletteButtonText'); }
        if (dataPropExists(e,'paletteHighlightBackground') && strlen(e.data('paletteHighlightBackground')) > 0) { ops.palette.highlight.background = e.data('paletteHighlightBackground'); }
        if (dataPropExists(e,'paletteHighlightBorder') && strlen(e.data('paletteHighlightBorder')) > 0) { ops.palette.highlight.border = e.data('paletteHighlightBorder'); }
        if (dataPropExists(e,'paletteHighlightText') && strlen(e.data('paletteHighlightText')) > 0) { ops.palette.highlight.text = e.data('paletteHighlightText'); }

        if (dataPropExists(e,'position')) { ops.position = e.data('position'); }
        if (dataPropExists(e,'theme') && in_array(e.data('theme'),[ 'classic', 'edgeless' ])) { ops.theme = e.data('theme'); }
        if (dataPropExists(e,'revokable') && in_array(e.data('revokable'),[ true, false ])) { ops.revokable = e.data('revokable'); }
        if (dataPropExists(e,'type') && in_array(e.data('type'),[ 'opt-in', 'opt-out' ])) {
            ops.type = e.data('type');

            ops.onInitialise = function (status) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                if (type == 'opt-in' && didConsent) {
                    cc_enableCookies();
                }
                if (type == 'opt-out' && !didConsent) {
                    cc_disableCookies();
                }
            };

            ops.onStatusChange = function(status, chosenBefore) {
                var type = this.options.type;
                var didConsent = this.hasConsented();
                if (type == 'opt-in' && didConsent) {
                    cc_enableCookies();
                }
                if (type == 'opt-out' && !didConsent) {
                    cc_disableCookies();
                }
            };

            ops.onRevokeChoice = function() {
                var type = this.options.type;
                if (type == 'opt-in') {
                    cc_disableCookies();
                }
                if (type == 'opt-out') {
                    cc_enableCookies();
                }
            };
        }

        if (ops.position === null) { delete ops['position']; }
        if (ops.position == 'top-pushdown' ) { ops['position'] = 'top'; ops["static"] = true; }

        if (ops.type === null) { delete ops['type']; }
        if (ops.theme === null) { delete ops['theme']; }


        window.cookieconsent.initialise(ops);
    }
}

function insertAndShift(arr, from, to) {
    cutOut = arr.splice(from, 1) [0];
    arr.splice(to, 0, cutOut);

    return arr;
}
function initMaxLenght(e) {
    if (elementExists(e)) {
        $(e).maxlength({
            alwaysShow: true,
            threshold: 20,
            warningClass: "badge badge-info",
            limitReachedClass: "badge badge-danger",
            placement: 'top-right-inside',
            separator : ' / '
        });
    }
}
function validateEmail(i) {
    'use strict';
    var regexEmail = /\S+@\S+\.\S+/;
    if (regexEmail.test(i)) {
        return true;
    } else {
        return false;
    }
}
function initTooltip(e) {
    $('body').tooltip({
        selector: e
    });


}
function destroyToolTips(e) {
    $(e).tooltip('dispose');
}

function initShowOnDocReady(e) {
    if (elementExists(e)) {
        $(e).removeClass('d-none');
    }
}

function initjScrollPane(e) {
    if (elementExists(e)) {
        $(e).jScrollPane();

        $(window).on('resize', function() {
            clearTimeout(config.windowResizeEnd);
            config.windowResizeEnd = setTimeout(function() {
                $(e).jScrollPane();
            }, 500);
        });
    }
}

function _confirm(title, msg, onOk, onCancel, opt) {

    if (typeof title == 'undefined' || title == null) title = '';
    if (typeof msg == 'undefined' || msg == null) msg = '';
    if (typeof onOk !== 'function' || typeof onCancel !== 'function') { cl('No functions passed to confirm dialog.'); return; }

    var options = {
        closableByDimmer : true,
        reverseButtons: true,
        moveBounded : true,
        transition:'fade',
        labels : {
            ok : 'OK',
            cancel : 'cancel'
        },
        message : msg
    };

    if (typeof opt !== 'undefined' && opt !== null && _.isObject(opt)) {
        _.map(opt, function (num, key) { options[key] = num; });
    }

    //alertify.set(options);
    //alertify.confirm(msg, type, alertify.options.delay, callBack() );

    //alertify.confirm(title, msg, onOk, onCancel).set(options);
    /*    alertify.confirm(confirmDialog.title, confirmDialog.message, ).set('labels', {ok:'<i class="fal fa-trash"></i> delete', cancel:'<i class="fal fa-times"></i> cancel'})
            ;*/



    /*

        if (typeof type == 'undefined' || type == null || !in_array(type,['success','error','warning','info'])) {
            type = 'info';
        }
        if (!callBack || typeof callBack !== "function") { callBack = function(){}; }
    */


}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function showConfirmModal(e, callback) {

    if (typeof callback === 'undefined' || typeof callback !== "function") { callback = false; }

    if (elementExists('#_confirmationModal')) {
        $('#_confirmationModal').remove();
    }
    var modalTitle = '';
    var modalBody = '';
    var modalClass = '';
    var modalCloseButtonLabel = 'close';
    var modalCancelButtonClass = '';
    var modalCancelButtonLabel = 'cancel';
    var modalOkButtonClass = '';
    var modalOkButtonLabel = 'ok';
    var modalHasCancelButton = true;
    var modalHasCloseButton = true;
    var backdrop = true;
    var modalkeyboard = true;


    if (dataPropExists(e,'modalclass') && strlen(e.data('modalclass'))>0) {
        modalClass = e.data('modalclass');
    }
    if (dataPropExists(e,'modaltitle') && strlen(e.data('modaltitle'))>0) {
        modalTitle = e.data('modaltitle');
    }
    if (dataPropExists(e,'modalbody') && strlen(e.data('modalbody'))>0) {
        modalBody = e.data('modalbody');
    }
    if (dataPropExists(e,'modalclosebuttonlabel') && strlen(e.data('modalclosebuttonlabel'))>0) {
        modalCloseButtonLabel = e.data('modalclosebuttonlabel');
    }
    if (dataPropExists(e,'modalcancelbuttonclass') && strlen(e.data('modalcancelbuttonclass'))>0) {
        modalCancelButtonClass = e.data('modalcancelbuttonclass');
    }
    if (dataPropExists(e,'modalcancelbuttonlabel') && strlen(e.data('modalcancelbuttonlabel'))>0) {
        modalCancelButtonLabel = e.data('modalcancelbuttonlabel');
    }
    if (dataPropExists(e,'modalokbuttonclass') && strlen(e.data('modalokbuttonclass'))>0) {
        modalOkButtonClass = e.data('modalokbuttonclass');
    }
    if (dataPropExists(e,'modalokbuttonlabel') && strlen(e.data('modalokbuttonlabel'))>0) {
        modalOkButtonLabel = e.data('modalokbuttonlabel');
    }
    if (dataPropExists(e,'modalhascancelbutton') && strlen(e.data('modalhascancelbutton'))>0 && !e.data('modalhascancelbutton')) {
        modalHasCancelButton = false;
    }
    if (dataPropExists(e,'modalhasclosebutton') && strlen(e.data('modalhasclosebutton'))>0 && !e.data('modalhasclosebutton')) {
        modalHasCloseButton = false;
    }
    if (dataPropExists(e,'modalbackdrop') && strlen(e.data('modalbackdrop'))>0) {
        backdrop = e.data('modalbackdrop');
        modalkeyboard = false;
    }


    var modal = '<div class="modal fade " id="_confirmationModal" tabindex="-1" role="dialog" aria-labelledby="_confirmationModalTitle" aria-hidden="true" data-backdrop="' + backdrop + '" data-keyboard="' + modalkeyboard + '">' +
            '        <div class="modal-dialog ' + modalClass + '" role="document">' +
            '            <div class="modal-content">';

    if (strlen(modalTitle)>0) {
        modal += '<div class="modal-header">' +
                    '<h5 class="modal-title" id="passwordChangePopupModalTitle">' + modalTitle + '</h5>' +
                    (modalHasCloseButton ? '<button type="button" class="close" data-dismiss="modal" aria-label="' + modalCloseButtonLabel + '"><span aria-hidden="true"></span></button>' : '' ) +
                 '</div>';
    }

    modal += '<div class="modal-body">' + modalBody + '</div>' +
                '<div class="modal-footer ' + (!modalHasCancelButton ? 'justify-content-center' : 'justify-content-between' ) + '">' +
                    (modalHasCancelButton ? '<button type="button" class="btn btn-secondary ' + modalCancelButtonClass + '" data-dismiss="modal" title="' + modalCancelButtonLabel + '">' + modalCancelButtonLabel + '</button>' : '' ) +
                    '<button type="button" class="btn btn-primary ' + modalOkButtonClass + ' _process" title="' + modalOkButtonLabel + '">' + modalOkButtonLabel + '</button>' +
                '</div>' +
            '</div>' +
            '</div>' +
            '</div>';

    $('body').append($(modal));

    if (callback) {
        $('#_confirmationModal ._process').data('callback',callback);
    }
    $('#_confirmationModal ._process').data('callerElement',e);


    $('#_confirmationModal').on('hide.bs.modal', function () {
        config.userInteractionStarted = false;
    });

    $('#_confirmationModal').on('shown.bs.modal', function () {
        config.userInteractionStarted = true;
        destroyToolTips('[data-toggle="tooltip"]');
    });

    $('#_confirmationModal').modal('show');

    $("body").off("click", "#_confirmationModal ._process", "**");
    $("body").on("click", "#_confirmationModal ._process", function () {
        var self = $(this);


        if (dataPropExists(e,'processaction')) {
            switch (e.data('processaction')) {
                case 'redirectToHref':
                    if (strlen(e.attr('href'))>0) {
                        showLoader();
                        loadUrl(e.attr('href'));
                    }
                break;
                case 'callback':
                    if (typeof callback === "function") {
                        callback(e);
                    }
                break;
                case 'reclickSelf':
                    self.data('callerElement').data('mayContinue',true);
                    self.data('callerElement').trigger('click');
                break;
                case 'reloadPage':
                    reloadUrl();
                break;
                case 'closeModal':
                    $('#_confirmationModal').modal('hide');
                break;
                default: break;
            }
        }
    });


}

function CK_baseInit() {
    if (_.isObject(ckEditorConfig.stylesSets)) {
        _.map(ckEditorConfig.stylesSets, function(v,k){
            CKEDITOR.stylesSet.add(k, v);
        });
    }
    ckEditorConfig.initiated = true;

}
function hideConfirmModal() {
    if (elementExists('#_confirmationModal')) {
        $('#_confirmationModal').modal('hide');
    }
}
function initCK(e) {
    if (elementExists(e)) {

        if (ckEditorConfig.initiated === false) {
            CK_baseInit()
        }

        $(e).each(function(i) {
            var options = {};
            var _id = $(this).attr('id');

            if (dataPropExists($(this),'ckeditorconfig') && _.isObject(ckEditorConfig[$(this).data('ckeditorconfig')])) {
                options = ckEditorConfig[$(this).data('ckeditorconfig')];
            }

            if (dataPropExists($(this),'ckeditortoolbar') && _.isObject(ckEditorConfig.toolbars[$(this).data('ckeditortoolbar')]) ) {
                options.toolbar = ckEditorConfig.toolbars[$(this).data('ckeditortoolbar')];
            }

            if (dataPropExists($(this),'maxchars') && is_numeric($(this).data('maxchars')) ) {
                options.wordcount.maxCharCount = $(this).data('maxchars');
            }

            if (dataPropExists($(this),'counthtmltags') && $(this).data('counthtmltags') ) {
                options.wordcount.countHTML = true;
            } else {
                options.wordcount.countHTML = false;
            }

            CKEDITOR.disableAutoInline = true;

            if (dataPropExists($(this),'isfull') && $(this).data('isfull') ) {
                CKEDITOR.replace( _id,  options);
            } else {
                CKEDITOR.inline( _id, options );
            }



            //CKEDITOR.replace( _id,  options);

            /*    $(this).ckeditor(config[options.ckBar]).on( 'getData.ckeditor', function( event, editor, data ) {
                    data.dataValue = cleanUpCkEContent(data.dataValue);
                } );*/

        });
    }
}
function getFlatOrder(e,pid) {
    var r = {};
    var pos = 0;

    _.map(e, function(v,k){
        pos++;

        if ($(v).data().hasOwnProperty('id') && is_numeric($(v).data('id'))) {
            r[$(v).data('id')] = { parent : pid, weight: pos };

            if ($(v).children('ul').children('li').length > 0) {
                r = _.extend(r,getFlatOrder($(v).children('ul').children('li'),$(v).data('id')));
            }

        }
    });
    return r;
}
function initTabbed(e) {
    if (elementExists(e)) {

        $( ".tabControllerTabsContainer > div" ).each(function(i) {
            if(!$(this).hasClass("activeTab")) { $(this).hide(); }
        });

        $( e + " > ul > li > a" ).each(function() {

            var container = $($(this).closest(e));

            if (!container.data().hasOwnProperty("nojs"))
            {
                var triggeredElement = $('[data-tabcontent-id="'+$(this).data('tabId')+'"]');

                var hash = $(this).data('tabId');

                $(this).off( "click", "**" );
                $(this).on( "click", function(el) {
                    el.preventDefault();
                    /*			container.children('div').each(function() {
                     $(this).removeClass("activeTab").hide();
                     });*/
                    if (container.hasClass('accordion')) {
                        $(this).parent().siblings('li').removeClass('active');
                        $(this).parent().toggleClass('active');
                        $(this).parent().parent().find('div.activeTab').removeClass('activeTab');

                        if (!$(this).parent().hasClass('active')) {
                            $(this).parent().children('div:first-of-type').removeClass('activeTab').slideUp();
                        } else {
                            triggeredElement.addClass("activeTab").fadeIn();
                        }
                    } else {
                        container.find('div.activeTab').removeClass('activeTab').hide();
                        triggeredElement.addClass("activeTab").fadeIn();

                        $(this).closest(e + " > ul").find('li').removeClass('active');
                        $(this).parent('li').addClass("active");
                    }
                    addHash(hash);
                });
            }
        });
        openTabFromHash();


        if ($(e).find('.has-error').length > 0 || $(e).find('.is-invalid').length > 0 ) {
            $(e).find('.has-error, .is-invalid').each(function(){
                var el = $(this);
                var p = el.closest('.tabbedContent');

                var cont = el.closest('.tabbed');
                if (elementExists(p) && elementExists(cont) && dataPropExists(p, 'tabcontentId') && cont.find('ul [data-tab-id="' + p.data('tabcontentId') + '"]').length > 0 ) {
                    $('ul [data-tab-id="' + p.data('tabcontentId') + '"]').parent().addClass('has-error');
                }

            });
        }



    }
}

function openTabFromHash(scroll){
    var hash = getHash();
    if (typeof scroll === 'undefined' || scroll === null || scroll.length < 1) { scroll = false; }

    if (hash) {
        var e = $.find("[data-tab-id='" + hash + "']");
        if (e.length) {
            $(e).trigger('click');

            if (scroll) {
                $('html, body').animate({
                    scrollTop: $(e).offset().top
                }, 500);
            }
        }
    }
}

function getHash() {
    if (window.location.hash) {
        return window.location.hash.substring(1);
    } else {
        return false;
    }
}

function addHash(h) {
    //window.location.hash = h;
    history.pushState(null, null, ('#'+h));
    return false;
}

function initTopMenu() {
    $("body").off("click", "header .__toggleMobileMenu", "**");
    $("body").on("click", "header .__toggleMobileMenu", function () {

        $('.__topMenuMobileContainer').toggleClass('shownOnMobile');

        if (elementExists('.__topMenuMobileOverlay')) {

            $('.__topMenuMobileOverlay').toggleClass('shownOnMobile');

            if ($('.__topMenuMobileOverlay').hasClass('shownOnMobile')) {

                /*if (elementExists('.nanoScroller')) {
                    $('.nanoScroller').nanoScroller();
                }*/

                lockScroll();
            } else {
                unlockScroll();
            }
        }

    });
    $("body").off("click touchstart", ".__topMenuMobileOverlay", "**");
    $("body").on("click touchstart", ".__topMenuMobileOverlay", function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('ul[data-menu="__topMenu"]').removeClass('shownOnMobile');
        $('.__topMenuMobileContainer').removeClass('shownOnMobile');

        $('.__topMenuMobileOverlay').removeClass('shownOnMobile');

        unlockScroll();

    });
}

function initSelect2inDT(e) {
    if (elementExists(e)) {
        $(e).select2({
            width: 'resolve',
            containerCss : {"display":"block"},
            dropdownAutoWidth : true,
            theme: "bootstrap",
            dropdownCssClass : '_select2DropDownClass',
            language: "en",
            dropdownParent: $(document.body)
        });
    }
}

function initSelect2() {
    $('.select2').each(function(){

        var e = $(this);
        var opt = {
            width: 'resolve',
            containerCss : {"display":"block"},
            dropdownAutoWidth : true,
            theme: "bootstrap",
            dropdownCssClass : '_select2DropDownClass',
            language: "hu"

        };

        if (dataPropExists(e,'placeholder')) {
            opt.placeholder = e.data('placeholder');
        }
        if (dataPropExists(e,'maximumselectionlength')) {
            opt.maximumSelectionLength = e.data('maximumselectionlength');
        }
        if (dataPropExists(e,'hasfilter') && !e.data('hasfilter')) {
            opt.minimumResultsForSearch = Infinity;
        }
        if (dataPropExists(e,'fullwidth') && e.data('fullwidth')) {
            opt.width = '100%';
        }
        if (dataPropExists(e,'addonclass') && strlen(e.data('addonclass'))>0) {
            opt.containerCssClass = e.data('addonclass');
        }
        if (dataPropExists(e,'dropdownparent') && strlen(e.data('dropdownparent'))>0 && elementExists(e.data('dropdownparent'))) {
            opt.dropdownParent = $(e.data('dropdownparent'));
        }

        e.select2(opt);
    });
}



function initDateRangePicker(e) {

    if (elementExists(e)) {
        $(e).each(function(i) {
            var self  = $(this);

            var opt = {
                showDropdowns: true,
                autoUpdateInput : true,
                alwaysShowCalendars : true,
                opens: "right",
                timePicker24Hour : true,
                buttonClasses: "btn btn-sm",
                applyButtonClasses: "btn btn-sm btn-primary",
                cancelClass: "btn btn-sm btn-secondary",
                /*ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },*/

                startDate: moment().startOf('hour').subtract(48, 'hours'),
                endDate: moment().startOf('hour'),


                locale: {
                    applyLabel: "Apply",
                    cancelLabel: "Cancel",
                    fromLabel: "",
                    toLabel: "",
                    format: 'YYYY-MM-DD'
                }
            };

            if (dataPropExists(self,'localeApplyLabel')) {
                opt['locale']['applyLabel'] = self.data('localeApplyLabel');
            }
            if (dataPropExists(self,'localeCancelLabel')) {
                opt['locale']['cancelLabel'] = self.data('localeCancelLabel');
            }
            if (dataPropExists(self,'singleDate')) {
                opt['singleDatePicker'] = true;
                opt['startDate'] = moment();
                opt['endDate'] = moment();
            }
            if (dataPropExists(self,'timePickerIncrement')) {
                opt['timePickerIncrement'] = self.data('timePickerIncrement');
            }
            if (dataPropExists(self,'timePickerSeconds')) {
                opt['timePickerSeconds'] = self.data('timePickerSeconds');
            }

            if ( self.hasClass('_dateRangePickerWithTime') ) {
                opt['timePicker'] = true;
                opt['timePickerSeconds'] = true;
                opt['locale']['format'] = 'YYYY-MM-DD HH:mm:ss';
            }

            if (dataPropExists(self,'dateformat')) {
                opt['locale']['format'] = self.data('dateformat');
            }

            if (dataPropExists(self,'cancelisclear')) {
                opt['locale']['cancelLabel'] = 'Clear';
            }

            if (dataPropExists(self,'defaultrange')) {
                var operation = self.data('defaultrange').split('|');

                if (_.isObject(operation) && _.size(operation) == 3 && in_array(operation[0],['add','subtract']) &&  is_numeric(operation[1]) && strlen(operation[2]) > 0) {
                    switch (operation[0]) {
                        case 'add':
                            opt['startDate'] = moment();
                            opt['endDate'] = moment().add(operation[1], operation[2]);
                        break;
                        case 'subtract':
                            opt['startDate'] = moment().subtract(operation[1], operation[2]);
                            opt['endDate'] = moment();
                        break;
                        default: break;
                    }
                }

            }

            if ( strlen(self.val())>0 ) {

                if (strlen(self.val()) < 20) {

                    var startDate = moment(self.val(), 'Y-MM-DD HH:mm:ss', true);
                    var endDate = moment(self.val(), 'Y-MM-DD HH:mm:ss', true);

                    if (startDate.isValid() && endDate.isValid()) {
                        opt['startDate'] = startDate;
                        opt['endDate'] = endDate;


                    }

                } else {

                    var tmp = self.val().split(" - ");

                    if (_.isObject(tmp) && _.size(tmp)==2) {
                        var startDate = moment(tmp[0]);
                        var endDate = moment(tmp[1]);

                        if (startDate.isValid() && endDate.isValid()) {
                            opt['startDate'] = startDate;
                            opt['endDate'] = endDate;
                        }


                    }
                }


            }

            if (dataPropExists(self,'startdate')) {
                var startDate = moment(self.data('startdate'), 'Y-MM-DD HH:mm:ss', true);

                if (startDate.isValid()) {
                    opt['startDate'] = self.data('startdate');
                }
            }
            if (dataPropExists(self,'enddate')) {
                var endDate = moment(self.data('enddate'), 'Y-MM-DD HH:mm:ss', true);

                if (endDate.isValid()) {
                    opt['endDate'] = self.data('enddate');
                }
            }

            var drp = $(self).daterangepicker(opt);

            if(self.hasClass('_singleDate')) {
                drp.on('show.daterangepicker', function(ev, picker) {
                    picker.container.addClass('_singleDate');
                });
            }

            if (dataPropExists(self,'cancelisclear')) {
                drp.on('cancel.daterangepicker', function(ev, picker) {
                    picker.element.val('');
                });
            }

        });

    }
}

function initDatePicker() {
    var opt = {
        language : 'en',
        format : 'yyyy-mm-dd',
        autoclose: true,
        todayHighlight : true,

        templates : {
            leftArrow: '<i class="fal fa-chevron-double-left"></i>',
            rightArrow: '<i class="fal fa-chevron-double-right"></i>'
        }
    };

    $('._datepicker').datepicker(opt);
}

function lockScroll(){
    $html = $('html');
    $body = $('body');
    var initWidth = $body.outerWidth();
    var initHeight = $body.outerHeight();

    var scrollPosition = [
        self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
        self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    ];

    $html.data('scroll-position', scrollPosition);
    $html.data('previous-overflow', $html.css('overflow'));
    $html.data('initWidth', initWidth);
    $html.css('overflow', 'hidden');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);

    var marginR = $body.outerWidth()-initWidth;
    var marginB = $body.outerHeight()-initHeight;
    $body.css({'margin-right': marginR,'margin-bottom': marginB});
}

function unlockScroll(){
    $html = $('html');
    $body = $('body');
    $html.css('overflow', $html.data('previous-overflow'));

    if (dataPropExists($html,'scroll-position')) {
        var scrollPosition = $html.data('scroll-position');
        window.scrollTo(scrollPosition[0], scrollPosition[1]);

        $body.css({'margin-right': 0, 'margin-bottom': 0});
    }

    $body.css( 'width', '100%' );

}
function loadUrl (url) {
    window.location = url;
    return false;
}
function reloadUrl() {
    window.location.reload(true);
    return false;
}
function showLoader() {
    if (!elementExists('.fullScreenLoader')) {
        $('body').append(fullScreenLoaderHtml);
    }
    $('.fullScreenLoader').stop().fadeIn();
}

function hideLoader() {

    if (elementExists('.fullScreenLoader')) {
        $('.fullScreenLoader').stop().fadeOut().remove();
    }
}
function isJSONString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function fText() {

}

function wrapResponse(s,t,c,addOnClass) {
    var r = '';
    if (typeof s === 'undefined' || s === null || s.length < 1) { cl('No string passed to wrapResponse.'); return; }
    if (typeof t === 'undefined' || t === null || t.length < 1) { var t = 'success'; }
    if (typeof c === 'undefined' || !_.contains([false, true], c)) { var c = false; }
    if (typeof addOnClass === 'undefined' || t === null || t.length < 1) { var addOnClass = ''; }

    var type = { 'success' : 'alert-success', 'info' : 'alert-info', 'warning' : 'alert-warning', 'error' : 'alert-danger' };

    r = '<div class="alert ' + type[t] + ' ';
    if (c) r += ' alert-dismissible  ';
    r += addOnClass + ' " role="alert">';

    if (c) r += '<button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>';
    r += s + '</div>';

    return r;
}
function populateImages(e) {
    $(e).each(function(i) {
        if ($(this).data().hasOwnProperty('imgSrc') && $(this).data('imgSrc').length>0) {
            $(this).css('background-image','url(' +$(this).data('imgSrc')+')');
            $(this).css('background-size','cover');

        }
    });
}
function notifyInModal(type, title, msg, opt, callBack) {

    var notifyModalOps = {
        hasTimeout : config.notifyModalTimeout
    };

    if (typeof opt !== 'undefined' && opt !== null && _.isObject(opt)) {
        _.map(opt, function (num, key) { notifyModalOps[key] = num; });
    }

    if (typeof type == 'undefined' || type == null || !in_array(type,['success','error','warning','info'])) {
        type = 'info';
    }
    if (!callBack || typeof callBack !== "function") { callBack = function(){}; }

    if (elementExists('#notifyModal')) {
        $('#notifyModal').modal('hide');
        $('#notifyModal').remove();
    }

    var modal = '<div class="modal fade ' + type + '" id="notifyModal" ' +
        'tabindex="-1" role="dialog" aria-labelledby="notifyModalTitle" aria-hidden="true">' +
        '        <div class="modal-dialog" role="document">' +
        '            <div class="modal-content">';

    if (strlen(title)>0) {
        modal += '<div class="modal-header">' +
            '<h5 class="modal-title" id="notifyModalTitle">' + title + '</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="close"><span aria-hidden="true"></span></button>' +
            '</div>';
    }

    modal += '<div class="modal-body">' + msg + '</div>' +

        '</div>' +
        '</div>' +
        '</div>';

    $('body').append($(modal));
    $('#notifyModal').modal('show');

    $('#notifyModal').on('hidden.bs.modal', function (e) {
        $('#notifyModal').remove();
    });

    if (notifyModalOps.hasTimeout) {
        setTimeout(function() { $('#notifyModal').modal('hide'); } , notifyModalOps.hasTimeout);
    }

}

function notify(type, msg, opt, callBack) {

    alertify.options = {
        position : 'top-center',
        delay: 4
    };

    if (typeof opt !== 'undefined' && opt !== null && _.isObject(opt)) {
        _.map(opt, function (num, key) { alertify.options[key] = num; });
    }

    if (typeof type == 'undefined' || type == null || !in_array(type,['success','error','warning','info'])) {
        type = 'info';
    }
    if (!callBack || typeof callBack !== "function") { callBack = function(){}; }

    alertify.set('notifier','position', alertify.options.position);
    alertify.notify(msg, type, alertify.options.delay, callBack() );
}

function _request(url, type, data, callBack, params) {
    'use strict';
    if (typeof url === 'undefined' || url === null || url.length < 1) { console.log('Invalid URL in the request.'); return; }
    if (typeof type === 'undefined' || !_.contains(['GET', 'POST', 'PATCH', 'DELETE','get', 'post', 'patch', 'delete'], type)) { cl('Invalid request type.'); return; }
    if (typeof data === 'undefined') { console.log('No data passed to the request.'); return; }
    if (!callBack || typeof callBack !== "function") { console.log('No callback defined.'); return; }

    var options = {
        dataType: "jsonp",
        jsonp: "callback",
        cache: false,
        async: true,
        showLoader: false,
        hideLoader: false,
        showNotify: false,
        processData: true,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        redirectOnAuthFail: true
    };

    if (typeof params !== 'undefined' && params !== null && _.isObject(params)) {
        _.map(params, function (num, key) { options[key] = num; });
    }

    $.ajax({
        url: url,
        type: type,
        dataType: options.dataType,
        data : data,
        jsonp: options.jsonp,
        cache: options.cache,
        async : options.async,
        processData : options.processData,
        contentType : options.contentType,
        beforeSend: function () {
            if (options.showLoader) {
                showLoader();
            }
        },
        success: function (result) {
            callBack(result);
        },
        error: function (xhr, status, err) {
            if (options.showNotify) {
                notify('error',('<strong>' + status + '</strong><br>' + err.toString()));

                if (xhr.status == 401 && options.redirectOnAuthFail == true) {
                    window.location.href = config.url;
                }
            }
        },
        complete: function () {
            if (options.hideLoader) {
                hideLoader();
            }
        }
    });
}

function elementExists(i) {

    var obj = (typeof i == "string") ? $(i) : i;

    return (obj.length);
}

function dataPropExists(element,propName) {
    e = false;

    if (typeof element !== 'string' && element.length > 0) {
        e = element;
    } else if ($(element).length > 0) {
        e = $(element);
    }

    if (e) {
        return e.data().hasOwnProperty(propName);
    } else {
        return false;
    }

}
function getDataProp(element,propName) {

    if (dataPropExists(element,propName)) {
        return element.data(propName);
    }

    return null;
}

function cl(s) {
    if (config.debug) {
        console.log(s);
    }
}


function dz(container, params) {

    var mimeTypes = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/x-windows-bmp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/x-msexcel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/x-rtf',
        'application/rtf',
        'text/richtext',
        'application/x-compressed',
        'application/x-zip-compressed',
        'application/zip',
        'application/x-rar-compressed',
        'application/x-7z-compressed',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.presentation',
        'application/pdf'
    ];

    var options = {
        url: '',
        params: { },
        paramName: "file", // The name that will be used to transfer the file
        maxFilesize: 2, // MB
        addRemoveLinks: true,
        acceptedFiles : mimeTypes.join(','),
        uploadMultiple: false,
        parallelUploads: 100,
        autoProcessQueue : false,


        error: function(file, response) {
            if($.type(response) === "string")
                var message = response; //dropzone sends it's own error messages in string
            else
                var message = response.message;
            file.previewElement.classList.add("dz-error");
            _ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
            _results = [];
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                node = _ref[_i];
                _results.push(node.textContent = message);
            }
            return _results;
        },

        init: function(f) {

            this.on("success", function(multiple, response) {
                cl('DZ success');
            }),
            this.on("queuecomplete", function(multiple, response) {
                cl('DZ queuecomplete');
            })

        }

    };

    if (typeof params !== 'undefined' && params !== null && _.isObject(params)) {
        _.map(params, function (num, key) { options[key] = num; });
    }

    return new Dropzone(container, options);
}