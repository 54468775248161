;
var _symfonyCollectionHolders = [];

function addToSymfonyCollection(holder, tools) {
    if (!dataPropExists(holder,'prototype')) {
        cl('ERROR: prototype not set on container element.');
        return;
    }

    var options = {
        prototype : holder.data('prototype'),
        index : holder.data('index'),
        position : (dataPropExists(holder,'newelementposition') && in_array(holder.data('newelementposition'),['start','end'])) ? holder.data('newelementposition') : 'top'
    };

    var newElement = options.prototype;
    newElement = newElement.replace(/__name__/g, options.index);

    holder.data('index', options.index + 1);

    newElementInContainer = tools.elementContainer.clone().append(newElement);

    if (options.position == 'start') {
        holder.find('li._toolContainer').first().after(newElementInContainer);
    } else {
        holder.find('li._toolContainer').last().before(newElementInContainer);
    }

    if (tools.hasDeleteButton) {
        removeFromSymfonyCollection(newElementInContainer, tools);
    }

    if ($(newElementInContainer).find('.select2')) {
        initSelect2();
    }

    tools.parentForm.data('isDirty',true);

}

function removeFromSymfonyCollection(element, tools) {

    var removeButton = tools.removeButton.clone();

    if (tools.deleteButtonContainer && element.find(tools.deleteButtonContainer).length > 0) {
        element.find(tools.deleteButtonContainer).append(removeButton);
    } else {
        element.append(removeButton);
    }

    removeButton.off("click");
    removeButton.on('click', function(event) {
        var self = $(this);

        if (dataPropExists(self,'preventdefault') && self.data('preventdefault')) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (dataPropExists(self,'preventdefault') && self.data('preventdefault')) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (tools.hasConfirm) {

            showConfirmModal(self, function () {
                element.remove();

                if (elementExists('#_confirmationModal')) {
                    $('#_confirmationModal').modal('hide');
                }
            });

        } else {
            element.remove();
        }

        tools.parentForm.data('isDirty',true);

    });
}

function initSymfonyCollections(e) {

    if (elementExists(e)) {

        $(e).each(function(mainIndex) {
            var collectionHolder = $(this);

            var addButtonParams = {
                className : dataPropExists(collectionHolder,'addbuttonclass') ? getDataProp(collectionHolder,'addbuttonclass') : '',
                title : dataPropExists(collectionHolder,'addbuttontitle') ? getDataProp(collectionHolder,'addbuttontitle') : '',
                html : dataPropExists(collectionHolder,'addbuttonhtml') ? getDataProp(collectionHolder,'addbuttonhtml') : ''
            };

            var deleteButtonParams = {
                className : dataPropExists(collectionHolder,'deletebuttonclass') ? getDataProp(collectionHolder,'deletebuttonclass') : '',
                title : dataPropExists(collectionHolder,'deletebuttontitle') ? getDataProp(collectionHolder,'deletebuttontitle') : '',
                html : dataPropExists(collectionHolder,'deletebuttonhtml') ? getDataProp(collectionHolder,'deletebuttonhtml') : '',
                hasDeleteButton : dataPropExists(collectionHolder,'hasdeletebutton') ? getDataProp(collectionHolder,'hasdeletebutton') : true,
                preventDefault : dataPropExists(collectionHolder,'deletebuttonpreventdefault') ? getDataProp(collectionHolder,'deletebuttonpreventdefault') : false,
                modalprocessaction : dataPropExists(collectionHolder,'deletemodalprocessaction') ? getDataProp(collectionHolder,'deletemodalprocessaction') : '',
                modaltitle : dataPropExists(collectionHolder,'deletemodaltitle') ? getDataProp(collectionHolder,'deletemodaltitle') : '',
                modalbody : dataPropExists(collectionHolder,'deletemodalbody') ? getDataProp(collectionHolder,'deletemodalbody') : '',
                modalclosebuttonlabel : dataPropExists(collectionHolder,'deletemodalclosebuttonlabel') ? getDataProp(collectionHolder,'deletemodalclosebuttonlabel') : '',
                modalcancelbuttonlabel : dataPropExists(collectionHolder,'deletemodalcancelbuttonlabel') ? getDataProp(collectionHolder,'deletemodalcancelbuttonlabel') : '',
                modalokbuttonlabel : dataPropExists(collectionHolder,'deletemodalokbuttonlabel') ? getDataProp(collectionHolder,'deletemodalokbuttonlabel') : '',
                deletebuttoncontainer : dataPropExists(collectionHolder,'deletebuttoncontainer') ? getDataProp(collectionHolder,'deletebuttoncontainer') : false,
                hasconfirm : dataPropExists(collectionHolder,'hasconfirm') ? getDataProp(collectionHolder,'hasconfirm') : false
            };

            var tools = {
                parentForm : (collectionHolder.closest('form')) ? collectionHolder.closest('form') : false,
                toolContainer : $('<li class="_toolContainer"></li>'),
                elementContainer : $('<li class="_symfonyCollection_item"></li>'),
                addButton : $('<a href="javascript:;" class="' + addButtonParams.className + ' _addToSymfonyCollection' + mainIndex + '" title="' + addButtonParams.title + '">' + addButtonParams.html + '</a>'),
                hasAddButton : (dataPropExists(collectionHolder,'hasaddbutton')) ? collectionHolder.data('hasaddbutton') : true,
                addButtonPosition : (dataPropExists(collectionHolder,'addbuttonposition') && in_array(collectionHolder.data('addbuttonposition'),['top','bottom'])) ? collectionHolder.data('addbuttonposition') : 'top',

                hasDeleteButton : deleteButtonParams.hasDeleteButton,
                deleteButtonContainer : deleteButtonParams.deletebuttoncontainer,

                removeButton : $('<a href="javascript:;" class="' + deleteButtonParams.className + ' _removeFromSymfonyCollection' + mainIndex + '" title="' + deleteButtonParams.title + '" ' +
                    ' data-preventDefault="' + deleteButtonParams.preventDefault + '" ' +
                    ' data-processaction="' + deleteButtonParams.modalprocessaction + '" ' +
                    ' data-modaltitle="' + deleteButtonParams.modaltitle + '" ' +
                    " data-modalbody='" + deleteButtonParams.modalbody + "' " +
                    ' data-modalclosebuttonlabel="' + deleteButtonParams.modalclosebuttonlabel + '" ' +
                    ' data-modalcancelbuttonlabel="' + deleteButtonParams.modalcancelbuttonlabel + '" ' +
                    ' data-modalokbuttonlabel="' + deleteButtonParams.modalokbuttonlabel + '" ' +
                    '>' + deleteButtonParams.html +  '</a>'),

                hasConfirm : deleteButtonParams.hasconfirm

            };

            _symfonyCollectionHolders[mainIndex] = collectionHolder;

            _symfonyCollectionHolders[mainIndex].data('index', _symfonyCollectionHolders[mainIndex].find('li._symfonyCollection_item:not(._toolContainer)').length);

            if (tools.hasAddButton) {
                if (tools.addButtonPosition == 'top') {
                    _symfonyCollectionHolders[mainIndex].prepend(tools.toolContainer.clone().append(tools.addButton));
                } else {
                    _symfonyCollectionHolders[mainIndex].append(tools.toolContainer.clone().append(tools.addButton));
                }
            }

            if (tools.hasDeleteButton) {
                _symfonyCollectionHolders[mainIndex].find('li._symfonyCollection_item:not(._toolContainer)').each(function() {
                    removeFromSymfonyCollection($(this), tools);
                });
            }

            $("body").off("click", ("._addToSymfonyCollection" + mainIndex), "**");
            $("body").on("click", ("._addToSymfonyCollection" + mainIndex), function (e) {
                e.preventDefault();
                e.stopPropagation();

                addToSymfonyCollection(_symfonyCollectionHolders[mainIndex], tools);
            });

        });

    }
}
