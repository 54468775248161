;
function implode (glue, pieces) {
    //  discuss at: http://locutus.io/php/implode/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Waldo Malqui Silva (http://waldo.malqui.info)
    // improved by: Itsacon (http://www.itsacon.net/)
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: implode(' ', ['Kevin', 'van', 'Zonneveld'])
    //   returns 1: 'Kevin van Zonneveld'
    //   example 2: implode(' ', {first:'Kevin', last: 'van Zonneveld'})
    //   returns 2: 'Kevin van Zonneveld'

    var i = ''
    var retVal = ''
    var tGlue = ''

    if (arguments.length === 1) {
        pieces = glue
        glue = ''
    }

    if (typeof pieces === 'object') {
        if (Object.prototype.toString.call(pieces) === '[object Array]') {
            return pieces.join(glue)
        }
        for (i in pieces) {
            retVal += tGlue + pieces[i]
            tGlue = glue
        }
        return retVal
    }

    return pieces
}
function microtime (getAsFloat) {
    //  discuss at: http://locutus.io/php/microtime/
    // original by: Paulo Freitas
    // improved by: Dumitru Uzun (http://duzun.me)
    //   example 1: var $timeStamp = microtime(true)
    //   example 1: $timeStamp > 1000000000 && $timeStamp < 2000000000
    //   returns 1: true
    //   example 2: /^0\.[0-9]{1,6} [0-9]{10,10}$/.test(microtime())
    //   returns 2: true

    var s
    var now
    if (typeof performance !== 'undefined' && performance.now) {
        now = (performance.now() + performance.timing.navigationStart) / 1e3
        if (getAsFloat) {
            return now
        }

        // Math.round(now)
        s = now | 0

        return (Math.round((now - s) * 1e6) / 1e6) + ' ' + s
    } else {
        now = (Date.now ? Date.now() : new Date().getTime()) / 1e3
        if (getAsFloat) {
            return now
        }

        // Math.round(now)
        s = now | 0

        return (Math.round((now - s) * 1e3) / 1e3) + ' ' + s
    }
}
function url_slug(s, opt) {
    s = String(s);
    opt = Object(opt);

    var defaults = {
        'delimiter': '-',
        'limit': undefined,
        'lowercase': true,
        'replacements': {},
        'transliterate': (typeof(XRegExp) === 'undefined') ? true : false
    };

    // Merge options
    for (var k in defaults) {
        if (!opt.hasOwnProperty(k)) {
            opt[k] = defaults[k];
        }
    }

    var char_map = {
        // Latin
        'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
        'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
        'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
        'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
        'ß': 'ss',
        'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
        'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
        'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
        'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
        'ÿ': 'y',

        // Latin symbols
        '©': '(c)',

        // Romanian
        'Ș': 'S', 'Ț': 'T', 'Ă': 'A',
        'ș': 's', 'ț': 't', 'ă': 'a',

        // Greek
        'Α': 'A', 'Β': 'B', 'Γ': 'G', 'Δ': 'D', 'Ε': 'E', 'Ζ': 'Z', 'Η': 'H', 'Θ': '8',
        'Ι': 'I', 'Κ': 'K', 'Λ': 'L', 'Μ': 'M', 'Ν': 'N', 'Ξ': '3', 'Ο': 'O', 'Π': 'P',
        'Ρ': 'R', 'Σ': 'S', 'Τ': 'T', 'Υ': 'Y', 'Φ': 'F', 'Χ': 'X', 'Ψ': 'PS', 'Ω': 'W',
        'Ά': 'A', 'Έ': 'E', 'Ί': 'I', 'Ό': 'O', 'Ύ': 'Y', 'Ή': 'H', 'Ώ': 'W', 'Ϊ': 'I',
        'Ϋ': 'Y',
        'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'h', 'θ': '8',
        'ι': 'i', 'κ': 'k', 'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': '3', 'ο': 'o', 'π': 'p',
        'ρ': 'r', 'σ': 's', 'τ': 't', 'υ': 'y', 'φ': 'f', 'χ': 'x', 'ψ': 'ps', 'ω': 'w',
        'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y', 'ή': 'h', 'ώ': 'w', 'ς': 's',
        'ϊ': 'i', 'ΰ': 'y', 'ϋ': 'y', 'ΐ': 'i',

        // Turkish
        'Ş': 'S', 'İ': 'I', 'Ç': 'C', 'Ü': 'U', 'Ö': 'O', 'Ğ': 'G',
        'ş': 's', 'ı': 'i', 'ç': 'c', 'ü': 'u', 'ö': 'o', 'ğ': 'g',

        // Russian
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh',
        'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
        'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C',
        'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sh', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
        'Я': 'Ya',
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
        'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c',
        'ч': 'ch', 'ш': 'sh', 'щ': 'sh', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
        'я': 'ya',

        // Ukrainian
        'Є': 'Ye', 'І': 'I', 'Ї': 'Yi', 'Ґ': 'G',
        'є': 'ye', 'і': 'i', 'ї': 'yi', 'ґ': 'g',

        // Czech
        'Č': 'C', 'Ď': 'D', 'Ě': 'E', 'Ň': 'N', 'Ř': 'R', 'Š': 'S', 'Ť': 'T', 'Ů': 'U',
        'Ž': 'Z',
        'č': 'c', 'ď': 'd', 'ě': 'e', 'ň': 'n', 'ř': 'r', 'š': 's', 'ť': 't', 'ů': 'u',
        'ž': 'z',

        // Polish
        'Ą': 'A', 'Ć': 'C', 'Ę': 'e', 'Ł': 'L', 'Ń': 'N', 'Ó': 'o', 'Ś': 'S', 'Ź': 'Z',
        'Ż': 'Z',
        'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z',
        'ż': 'z',

        // Latvian
        'Ā': 'A', 'Č': 'C', 'Ē': 'E', 'Ģ': 'G', 'Ī': 'i', 'Ķ': 'k', 'Ļ': 'L', 'Ņ': 'N',
        'Š': 'S', 'Ū': 'u', 'Ž': 'Z',
        'ā': 'a', 'č': 'c', 'ē': 'e', 'ģ': 'g', 'ī': 'i', 'ķ': 'k', 'ļ': 'l', 'ņ': 'n',
        'š': 's', 'ū': 'u', 'ž': 'z'
    };

    // Make custom replacements
    for (var k in opt.replacements) {
        s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
    }

    // Transliterate characters to ASCII
    if (opt.transliterate) {
        for (var k in char_map) {
            s = s.replace(RegExp(k, 'g'), char_map[k]);
        }
    }

    // Replace non-alphanumeric characters with our delimiter
    var alnum = (typeof(XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
    s = s.replace(alnum, opt.delimiter);

    // Remove duplicate delimiters
    s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

    // Truncate slug to max. characters
    s = s.substring(0, opt.limit);

    // Remove delimiter from ends
    s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

    return opt.lowercase ? s.toLowerCase() : s;
}

function is_numeric (mixedVar) { // eslint-disable-line camelcase
    //  discuss at: http://locutus.io/php/is_numeric/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: David
    // improved by: taith
    // bugfixed by: Tim de Koning
    // bugfixed by: WebDevHobo (http://webdevhobo.blogspot.com/)
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    // bugfixed by: Denis Chenu (http://shnoulle.net)
    //   example 1: is_numeric(186.31)
    //   returns 1: true
    //   example 2: is_numeric('Kevin van Zonneveld')
    //   returns 2: false
    //   example 3: is_numeric(' +186.31e2')
    //   returns 3: true
    //   example 4: is_numeric('')
    //   returns 4: false
    //   example 5: is_numeric([])
    //   returns 5: false
    //   example 6: is_numeric('1 ')
    //   returns 6: false
    var whitespace = [
        ' ',
        '\n',
        '\r',
        '\t',
        '\f',
        '\x0b',
        '\xa0',
        '\u2000',
        '\u2001',
        '\u2002',
        '\u2003',
        '\u2004',
        '\u2005',
        '\u2006',
        '\u2007',
        '\u2008',
        '\u2009',
        '\u200a',
        '\u200b',
        '\u2028',
        '\u2029',
        '\u3000'
    ].join('')
    // @todo: Break this up using many single conditions with early returns
    return (typeof mixedVar === 'number' ||
        (typeof mixedVar === 'string' &&
            whitespace.indexOf(mixedVar.slice(-1)) === -1)) &&
        mixedVar !== '' &&
        !isNaN(mixedVar)
}
function trim (str, charlist) {
    //  discuss at: http://locutus.io/php/trim/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: mdsjack (http://www.mdsjack.bo.it)
    // improved by: Alexander Ermolaev (http://snippets.dzone.com/user/AlexanderErmolaev)
    // improved by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Steven Levithan (http://blog.stevenlevithan.com)
    // improved by: Jack
    //    input by: Erkekjetter
    //    input by: DxGx
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //   example 1: trim('    Kevin van Zonneveld    ')
    //   returns 1: 'Kevin van Zonneveld'
    //   example 2: trim('Hello World', 'Hdle')
    //   returns 2: 'o Wor'
    //   example 3: trim(16, 1)
    //   returns 3: '6'

    var whitespace = [
        ' ',
        '\n',
        '\r',
        '\t',
        '\f',
        '\x0b',
        '\xa0',
        '\u2000',
        '\u2001',
        '\u2002',
        '\u2003',
        '\u2004',
        '\u2005',
        '\u2006',
        '\u2007',
        '\u2008',
        '\u2009',
        '\u200a',
        '\u200b',
        '\u2028',
        '\u2029',
        '\u3000'
    ].join('')
    var l = 0
    var i = 0
    str += ''

    if (charlist) {
        whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    }

    l = str.length
    for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(i)
            break
        }
    }

    l = str.length
    for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(0, i + 1)
            break
        }
    }

    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
}
function isset () {
    //  discuss at: http://locutus.io/php/isset/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: FremyCompany
    // improved by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Rafał Kukawski (http://blog.kukawski.pl)
    //   example 1: isset( undefined, true)
    //   returns 1: false
    //   example 2: isset( 'Kevin van Zonneveld' )
    //   returns 2: true

    var a = arguments
    var l = a.length
    var i = 0
    var undef

    if (l === 0) {
        throw new Error('Empty isset')
    }

    while (i !== l) {
        if (a[i] === undef || a[i] === null) {
            return false
        }
        i++
    }

    return true
}
function in_array (needle, haystack, argStrict) { // eslint-disable-line camelcase
    //  discuss at: http://locutus.io/php/in_array/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: vlado houba
    // improved by: Jonas Sciangula Street (Joni2Back)
    //    input by: Billy
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: in_array('van', ['Kevin', 'van', 'Zonneveld'])
    //   returns 1: true
    //   example 2: in_array('vlado', {0: 'Kevin', vlado: 'van', 1: 'Zonneveld'})
    //   returns 2: false
    //   example 3: in_array(1, ['1', '2', '3'])
    //   example 3: in_array(1, ['1', '2', '3'], false)
    //   returns 3: true
    //   returns 3: true
    //   example 4: in_array(1, ['1', '2', '3'], true)
    //   returns 4: false
    var key = ''
    var strict = !!argStrict
    // we prevent the double check (strict && arr[key] === ndl) || (!strict && arr[key] === ndl)
    // in just one for, in order to improve the performance
    // deciding wich type of comparation will do before walk array
    if (strict) {
        for (key in haystack) {
            if (haystack[key] === needle) {
                return true
            }
        }
    } else {
        for (key in haystack) {
            if (haystack[key] == needle) { // eslint-disable-line eqeqeq
                return true
            }
        }
    }
    return false
}
function rtrim (str, charlist) {
    //  discuss at: http://locutus.io/php/rtrim/
    // original by: Kevin van Zonneveld (http://kvz.io)
    //    input by: Erkekjetter
    //    input by: rem
    // improved by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Brett Zamir (http://brett-zamir.me)
    //   example 1: rtrim('    Kevin van Zonneveld    ')
    //   returns 1: '    Kevin van Zonneveld'

    charlist = !charlist ? ' \\s\u00A0' : (charlist + '')
        .replace(/([[\]().?/*{}+$^:])/g, '\\$1')

    var re = new RegExp('[' + charlist + ']+$', 'g')

    return (str + '').replace(re, '')
}
function substr (str, start, len) {
    //  discuss at: http://locutus.io/php/substr/
    // original by: Martijn Wieringa
    // bugfixed by: T.Wild
    // improved by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //  revised by: Theriault (https://github.com/Theriault)
    //      note 1: Handles rare Unicode characters if 'unicode.semantics' ini (PHP6) is set to 'on'
    //   example 1: substr('abcdef', 0, -1)
    //   returns 1: 'abcde'
    //   example 2: substr(2, 0, -6)
    //   returns 2: false
    //   example 3: ini_set('unicode.semantics', 'on')
    //   example 3: substr('a\uD801\uDC00', 0, -1)
    //   returns 3: 'a'
    //   example 4: ini_set('unicode.semantics', 'on')
    //   example 4: substr('a\uD801\uDC00', 0, 2)
    //   returns 4: 'a\uD801\uDC00'
    //   example 5: ini_set('unicode.semantics', 'on')
    //   example 5: substr('a\uD801\uDC00', -1, 1)
    //   returns 5: '\uD801\uDC00'
    //   example 6: ini_set('unicode.semantics', 'on')
    //   example 6: substr('a\uD801\uDC00z\uD801\uDC00', -3, 2)
    //   returns 6: '\uD801\uDC00z'
    //   example 7: ini_set('unicode.semantics', 'on')
    //   example 7: substr('a\uD801\uDC00z\uD801\uDC00', -3, -1)
    //   returns 7: '\uD801\uDC00z'
    //        test: skip-3 skip-4 skip-5 skip-6 skip-7
    str += ''
    var end = str.length
    var iniVal = (typeof require !== 'undefined' ? require('../info/ini_get')('unicode.emantics') : undefined) || 'off'
    if (iniVal === 'off') {
        // assumes there are no non-BMP characters;
        // if there may be such characters, then it is best to turn it on (critical in true XHTML/XML)
        if (start < 0) {
            start += end
        }
        if (typeof len !== 'undefined') {
            if (len < 0) {
                end = len + end
            } else {
                end = len + start
            }
        }
        // PHP returns false if start does not fall within the string.
        // PHP returns false if the calculated end comes before the calculated start.
        // PHP returns an empty string if start and end are the same.
        // Otherwise, PHP returns the portion of the string from start to end.
        if (start >= str.length || start < 0 || start > end) {
            return false
        }
        return str.slice(start, end)
    }
    // Full-blown Unicode including non-Basic-Multilingual-Plane characters
    var i = 0
    var allBMP = true
    var es = 0
    var el = 0
    var se = 0
    var ret = ''
    for (i = 0; i < str.length; i++) {
        if (/[\uD800-\uDBFF]/.test(str.charAt(i)) && /[\uDC00-\uDFFF]/.test(str.charAt(i + 1))) {
            allBMP = false
            break
        }
    }
    if (!allBMP) {
        if (start < 0) {
            for (i = end - 1, es = (start += end); i >= es; i--) {
                if (/[\uDC00-\uDFFF]/.test(str.charAt(i)) && /[\uD800-\uDBFF]/.test(str.charAt(i - 1))) {
                    start--
                    es--
                }
            }
        } else {
            var surrogatePairs = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g
            while ((surrogatePairs.exec(str)) !== null) {
                var li = surrogatePairs.lastIndex
                if (li - 2 < start) {
                    start++
                } else {
                    break
                }
            }
        }
        if (start >= end || start < 0) {
            return false
        }
        if (len < 0) {
            for (i = end - 1, el = (end += len); i >= el; i--) {
                if (/[\uDC00-\uDFFF]/.test(str.charAt(i)) && /[\uD800-\uDBFF]/.test(str.charAt(i - 1))) {
                    end--
                    el--
                }
            }
            if (start > end) {
                return false
            }
            return str.slice(start, end)
        } else {
            se = start + len
            for (i = start; i < se; i++) {
                ret += str.charAt(i)
                if (/[\uD800-\uDBFF]/.test(str.charAt(i)) && /[\uDC00-\uDFFF]/.test(str.charAt(i + 1))) {
                    // Go one further, since one of the "characters" is part of a surrogate pair
                    se++
                }
            }
            return ret
        }
    }
}

function ucfirst (str) {
    //  discuss at: http://locutus.io/php/ucfirst/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //   example 1: ucfirst('kevin van zonneveld')
    //   returns 1: 'Kevin van zonneveld'

    str += ''
    var f = str.charAt(0)
        .toUpperCase()
    return f + str.substr(1)
}

function i18n_loc_get_default () { // eslint-disable-line camelcase
                                   //  discuss at: http://locutus.io/php/i18n_loc_get_default/
                                   // original by: Brett Zamir (http://brett-zamir.me)
                                   //      note 1: Renamed in PHP6 from locale_get_default(). Not listed yet at php.net.
                                   //      note 1: List of locales at <http://demo.icu-project.org/icu-bin/locexp>
                                   //      note 1: To be usable with sort() if it is passed the `SORT_LOCALE_STRING`
                                   //      note 1: sorting flag: http://php.net/manual/en/function.sort.php
                                   //   example 1: i18n_loc_get_default()
                                   //   returns 1: 'en_US_POSIX'
                                   //   example 2: i18n_loc_set_default('pt_PT')
                                   //   example 2: i18n_loc_get_default()
                                   //   returns 2: 'pt_PT'

    var $global = (typeof window !== 'undefined' ? window : global)
    $global.$locutus = $global.$locutus || {}
    var $locutus = $global.$locutus
    $locutus.php = $locutus.php || {}
    $locutus.php.locales = $locutus.php.locales || {}

    return $locutus.php.locale_default || 'en_US_POSIX'
}

function sort (inputArr, sortFlags) {
    //  discuss at: http://locutus.io/php/sort/
    // original by: Kevin van Zonneveld (http://kvz.io)
    //  revised by: Brett Zamir (http://brett-zamir.me)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //      note 1: SORT_STRING (as well as natsort and natcasesort) might also be
    //      note 1: integrated into all of these functions by adapting the code at
    //      note 1: http://sourcefrog.net/projects/natsort/natcompare.js
    //      note 1: This function deviates from PHP in returning a copy of the array instead
    //      note 1: of acting by reference and returning true; this was necessary because
    //      note 1: IE does not allow deleting and re-adding of properties without caching
    //      note 1: of property position; you can set the ini of "locutus.sortByReference" to true to
    //      note 1: get the PHP behavior, but use this only if you are in an environment
    //      note 1: such as Firefox extensions where for-in iteration order is fixed and true
    //      note 1: property deletion is supported. Note that we intend to implement the PHP
    //      note 1: behavior by default if IE ever does allow it; only gives shallow copy since
    //      note 1: is by reference in PHP anyways
    //      note 1: Since JS objects' keys are always strings, and (the
    //      note 1: default) SORT_REGULAR flag distinguishes by key type,
    //      note 1: if the content is a numeric string, we treat the
    //      note 1: "original type" as numeric.
    //   example 1: var $arr = ['Kevin', 'van', 'Zonneveld']
    //   example 1: sort($arr)
    //   example 1: var $result = $arr
    //   returns 1: ['Kevin', 'Zonneveld', 'van']
    //   example 2: ini_set('locutus.sortByReference', true)
    //   example 2: var $fruits = {d: 'lemon', a: 'orange', b: 'banana', c: 'apple'}
    //   example 2: sort($fruits)
    //   example 2: var $result = $fruits
    //   returns 2: {0: 'apple', 1: 'banana', 2: 'lemon', 3: 'orange'}
    //        test: skip-1

    var i18nlgd = i18n_loc_get_default();

    var sorter
    var i
    var k
    var sortByReference = false
    var populateArr = {}

    var $global = (typeof window !== 'undefined' ? window : global)
    $global.$locutus = $global.$locutus || {}
    var $locutus = $global.$locutus
    $locutus.php = $locutus.php || {}
    $locutus.php.locales = $locutus.php.locales || {}

    switch (sortFlags) {
        case 'SORT_STRING':
            // compare items as strings
            // leave sorter undefined, so built-in comparison is used
            break
        case 'SORT_LOCALE_STRING':
            // compare items as strings, based on the current locale
            // (set with i18n_loc_set_default() as of PHP6)
            var loc = $locutus.php.locales[i18nlgd()]

            if (loc && loc.sorting) {
                // if sorting exists on locale object, use it
                // otherwise let sorter be undefined
                // to fallback to built-in behavior
                sorter = loc.sorting
            }
            break
        case 'SORT_NUMERIC':
            // compare items numerically
            sorter = function (a, b) {
                return (a - b)
            }
            break
        case 'SORT_REGULAR':
        default:
            sorter = function (a, b) {
                var aFloat = parseFloat(a)
                var bFloat = parseFloat(b)
                var aNumeric = aFloat + '' === a
                var bNumeric = bFloat + '' === b

                if (aNumeric && bNumeric) {
                    return aFloat > bFloat ? 1 : aFloat < bFloat ? -1 : 0
                } else if (aNumeric && !bNumeric) {
                    return 1
                } else if (!aNumeric && bNumeric) {
                    return -1
                }

                return a > b ? 1 : a < b ? -1 : 0
            }
            break
    }

    var iniVal = (typeof require !== 'undefined' ? require('../info/ini_get')('locutus.sortByReference') : undefined) || 'on'
    sortByReference = iniVal === 'on'
    populateArr = sortByReference ? inputArr : populateArr

    var valArr = []
    for (k in inputArr) {
        // Get key and value arrays
        if (inputArr.hasOwnProperty(k)) {
            valArr.push(inputArr[k])
            if (sortByReference) {
                delete inputArr[k]
            }
        }
    }

    valArr.sort(sorter)

    for (i = 0; i < valArr.length; i++) {
        // Repopulate the old array
        populateArr[i] = valArr[i]
    }
    return sortByReference || populateArr
}

function str_pad (input, padLength, padString, padType) { // eslint-disable-line camelcase
                                                          //  discuss at: http://locutus.io/php/str_pad/
                                                          // original by: Kevin van Zonneveld (http://kvz.io)
                                                          // improved by: Michael White (http://getsprink.com)
                                                          //    input by: Marco van Oort
                                                          // bugfixed by: Brett Zamir (http://brett-zamir.me)
                                                          //   example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT')
                                                          //   returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
                                                          //   example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH')
                                                          //   returns 2: '------Kevin van Zonneveld-----'

    var half = ''
    var padToGo

    var _strPadRepeater = function (s, len) {
        var collect = ''

        while (collect.length < len) {
            collect += s
        }
        collect = collect.substr(0, len)

        return collect
    }

    input += ''
    padString = padString !== undefined ? padString : ' '

    if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
        padType = 'STR_PAD_RIGHT'
    }
    if ((padToGo = padLength - input.length) > 0) {
        if (padType === 'STR_PAD_LEFT') {
            input = _strPadRepeater(padString, padToGo) + input
        } else if (padType === 'STR_PAD_RIGHT') {
            input = input + _strPadRepeater(padString, padToGo)
        } else if (padType === 'STR_PAD_BOTH') {
            half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
            input = half + input + half
            input = input.substr(0, padLength)
        }
    }

    return input
}
function count (mixedVar, mode) {
    //  discuss at: http://locutus.io/php/count/
    // original by: Kevin van Zonneveld (http://kvz.io)
    //    input by: Waldo Malqui Silva (http://waldo.malqui.info)
    //    input by: merabi
    // bugfixed by: Soren Hansen
    // bugfixed by: Olivier Louvignes (http://mg-crea.com/)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //   example 1: count([[0,0],[0,-4]], 'COUNT_RECURSIVE')
    //   returns 1: 6
    //   example 2: count({'one' : [1,2,3,4,5]}, 'COUNT_RECURSIVE')
    //   returns 2: 6

    var key
    var cnt = 0

    if (mixedVar === null || typeof mixedVar === 'undefined') {
        return 0
    } else if (mixedVar.constructor !== Array && mixedVar.constructor !== Object) {
        return 1
    }

    if (mode === 'COUNT_RECURSIVE') {
        mode = 1
    }
    if (mode !== 1) {
        mode = 0
    }

    for (key in mixedVar) {
        if (mixedVar.hasOwnProperty(key)) {
            cnt++
            if (mode === 1 && mixedVar[key] &&
                (mixedVar[key].constructor === Array ||
                    mixedVar[key].constructor === Object)) {
                cnt += count(mixedVar[key], 1)
            }
        }
    }

    return cnt
}
function str_replace (search, replace, subject, countObj) { // eslint-disable-line camelcase
    //  discuss at: http://locutus.io/php/str_replace/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Gabriel Paderni
    // improved by: Philip Peterson
    // improved by: Simon Willison (http://simonwillison.net)
    // improved by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Brett Zamir (http://brett-zamir.me)
    //  revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // bugfixed by: Anton Ongson
    // bugfixed by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Oleg Eremeev
    // bugfixed by: Glen Arason (http://CanadianDomainRegistry.ca)
    // bugfixed by: Glen Arason (http://CanadianDomainRegistry.ca)
    //    input by: Onno Marsman (https://twitter.com/onnomarsman)
    //    input by: Brett Zamir (http://brett-zamir.me)
    //    input by: Oleg Eremeev
    //      note 1: The countObj parameter (optional) if used must be passed in as a
    //      note 1: object. The count will then be written by reference into it's `value` property
    //   example 1: str_replace(' ', '.', 'Kevin van Zonneveld')
    //   returns 1: 'Kevin.van.Zonneveld'
    //   example 2: str_replace(['{name}', 'l'], ['hello', 'm'], '{name}, lars')
    //   returns 2: 'hemmo, mars'
    //   example 3: str_replace(Array('S','F'),'x','ASDFASDF')
    //   returns 3: 'AxDxAxDx'
    //   example 4: var countObj = {}
    //   example 4: str_replace(['A','D'], ['x','y'] , 'ASDFASDF' , countObj)
    //   example 4: var $result = countObj.value
    //   returns 4: 4
    var i = 0
    var j = 0
    var temp = ''
    var repl = ''
    var sl = 0
    var fl = 0
    var f = [].concat(search)
    var r = [].concat(replace)
    var s = subject
    var ra = Object.prototype.toString.call(r) === '[object Array]'
    var sa = Object.prototype.toString.call(s) === '[object Array]'
    s = [].concat(s)
    var $global = (typeof window !== 'undefined' ? window : global)
    $global.$locutus = $global.$locutus || {}
    var $locutus = $global.$locutus
    $locutus.php = $locutus.php || {}
    if (typeof (search) === 'object' && typeof (replace) === 'string') {
        temp = replace
        replace = []
        for (i = 0; i < search.length; i += 1) {
            replace[i] = temp
        }
        temp = ''
        r = [].concat(replace)
        ra = Object.prototype.toString.call(r) === '[object Array]'
    }
    if (typeof countObj !== 'undefined') {
        countObj.value = 0
    }
    for (i = 0, sl = s.length; i < sl; i++) {
        if (s[i] === '') {
            continue
        }
        for (j = 0, fl = f.length; j < fl; j++) {
            temp = s[i] + ''
            repl = ra ? (r[j] !== undefined ? r[j] : '') : r[0]
            s[i] = (temp).split(f[j]).join(repl)
            if (typeof countObj !== 'undefined') {
                countObj.value += ((temp.split(f[j])).length - 1)
            }
        }
    }
    return sa ? s : s[0]
}

function strlen (string) {
    //  discuss at: http://locutus.io/php/strlen/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Sakimori
    // improved by: Kevin van Zonneveld (http://kvz.io)
    //    input by: Kirk Strobeck
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //  revised by: Brett Zamir (http://brett-zamir.me)
    //      note 1: May look like overkill, but in order to be truly faithful to handling all Unicode
    //      note 1: characters and to this function in PHP which does not count the number of bytes
    //      note 1: but counts the number of characters, something like this is really necessary.
    //   example 1: strlen('Kevin van Zonneveld')
    //   returns 1: 19
    //   example 2: ini_set('unicode.semantics', 'on')
    //   example 2: strlen('A\ud87e\udc04Z')
    //   returns 2: 3
    var str = string + ''
    var iniVal = (typeof require !== 'undefined' ? require('../info/ini_get')('unicode.semantics') : undefined) || 'off'
    if (iniVal === 'off') {
        return str.length
    }
    var i = 0
    var lgth = 0
    var getWholeChar = function (str, i) {
        var code = str.charCodeAt(i)
        var next = ''
        var prev = ''
        if (code >= 0xD800 && code <= 0xDBFF) {
            // High surrogate (could change last hex to 0xDB7F to
            // treat high private surrogates as single characters)
            if (str.length <= (i + 1)) {
                throw new Error('High surrogate without following low surrogate')
            }
            next = str.charCodeAt(i + 1)
            if (next < 0xDC00 || next > 0xDFFF) {
                throw new Error('High surrogate without following low surrogate')
            }
            return str.charAt(i) + str.charAt(i + 1)
        } else if (code >= 0xDC00 && code <= 0xDFFF) {
            // Low surrogate
            if (i === 0) {
                throw new Error('Low surrogate without preceding high surrogate')
            }
            prev = str.charCodeAt(i - 1)
            if (prev < 0xD800 || prev > 0xDBFF) {
                // (could change last hex to 0xDB7F to treat high private surrogates
                // as single characters)
                throw new Error('Low surrogate without preceding high surrogate')
            }
            // We can pass over low surrogates now as the second
            // component in a pair which we have already processed
            return false
        }
        return str.charAt(i)
    }
    for (i = 0, lgth = 0; i < str.length; i++) {
        if ((getWholeChar(str, i)) === false) {
            continue
        }
        // Adapt this line at the top of any loop, passing in the whole string and
        // the current iteration and returning a variable to represent the individual character;
        // purpose is to treat the first part of a surrogate pair as the whole character and then
        // ignore the second part
        lgth++
    }
    return lgth
}

function strrev (string) {
    //       discuss at: http://locutus.io/php/strrev/
    //      original by: Kevin van Zonneveld (http://kvz.io)
    //      bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // reimplemented by: Brett Zamir (http://brett-zamir.me)
    //        example 1: strrev('Kevin van Zonneveld')
    //        returns 1: 'dlevennoZ nav niveK'
    //        example 2: strrev('a\u0301haB')
    //        returns 2: 'Baha\u0301' // combining
    //        example 3: strrev('A\uD87E\uDC04Z')
    //        returns 3: 'Z\uD87E\uDC04A' // surrogates
    //             test: 'skip-3'
    string = string + ''
    // Performance will be enhanced with the next two lines of code commented
    // out if you don't care about combining characters
    // Keep Unicode combining characters together with the character preceding
    // them and which they are modifying (as in PHP 6)
    // See http://unicode.org/reports/tr44/#Property_Table (Me+Mn)
    // We also add the low surrogate range at the beginning here so it will be
    // maintained with its preceding high surrogate
    var chars = [
        '\uDC00-\uDFFF',
        '\u0300-\u036F',
        '\u0483-\u0489',
        '\u0591-\u05BD',
        '\u05BF',
        '\u05C1',
        '\u05C2',
        '\u05C4',
        '\u05C5',
        '\u05C7',
        '\u0610-\u061A',
        '\u064B-\u065E',
        '\u0670',
        '\u06D6-\u06DC',
        '\u06DE-\u06E4',
        '\u06E7\u06E8',
        '\u06EA-\u06ED',
        '\u0711',
        '\u0730-\u074A',
        '\u07A6-\u07B0',
        '\u07EB-\u07F3',
        '\u0901-\u0903',
        '\u093C',
        '\u093E-\u094D',
        '\u0951-\u0954',
        '\u0962',
        '\u0963',
        '\u0981-\u0983',
        '\u09BC',
        '\u09BE-\u09C4',
        '\u09C7',
        '\u09C8',
        '\u09CB-\u09CD',
        '\u09D7',
        '\u09E2',
        '\u09E3',
        '\u0A01-\u0A03',
        '\u0A3C',
        '\u0A3E-\u0A42',
        '\u0A47',
        '\u0A48',
        '\u0A4B-\u0A4D',
        '\u0A51',
        '\u0A70',
        '\u0A71',
        '\u0A75',
        '\u0A81-\u0A83',
        '\u0ABC',
        '\u0ABE-\u0AC5',
        '\u0AC7-\u0AC9',
        '\u0ACB-\u0ACD',
        '\u0AE2',
        '\u0AE3',
        '\u0B01-\u0B03',
        '\u0B3C',
        '\u0B3E-\u0B44',
        '\u0B47',
        '\u0B48',
        '\u0B4B-\u0B4D',
        '\u0B56',
        '\u0B57',
        '\u0B62',
        '\u0B63',
        '\u0B82',
        '\u0BBE-\u0BC2',
        '\u0BC6-\u0BC8',
        '\u0BCA-\u0BCD',
        '\u0BD7',
        '\u0C01-\u0C03',
        '\u0C3E-\u0C44',
        '\u0C46-\u0C48',
        '\u0C4A-\u0C4D',
        '\u0C55',
        '\u0C56',
        '\u0C62',
        '\u0C63',
        '\u0C82',
        '\u0C83',
        '\u0CBC',
        '\u0CBE-\u0CC4',
        '\u0CC6-\u0CC8',
        '\u0CCA-\u0CCD',
        '\u0CD5',
        '\u0CD6',
        '\u0CE2',
        '\u0CE3',
        '\u0D02',
        '\u0D03',
        '\u0D3E-\u0D44',
        '\u0D46-\u0D48',
        '\u0D4A-\u0D4D',
        '\u0D57',
        '\u0D62',
        '\u0D63',
        '\u0D82',
        '\u0D83',
        '\u0DCA',
        '\u0DCF-\u0DD4',
        '\u0DD6',
        '\u0DD8-\u0DDF',
        '\u0DF2',
        '\u0DF3',
        '\u0E31',
        '\u0E34-\u0E3A',
        '\u0E47-\u0E4E',
        '\u0EB1',
        '\u0EB4-\u0EB9',
        '\u0EBB',
        '\u0EBC',
        '\u0EC8-\u0ECD',
        '\u0F18',
        '\u0F19',
        '\u0F35',
        '\u0F37',
        '\u0F39',
        '\u0F3E',
        '\u0F3F',
        '\u0F71-\u0F84',
        '\u0F86',
        '\u0F87',
        '\u0F90-\u0F97',
        '\u0F99-\u0FBC',
        '\u0FC6',
        '\u102B-\u103E',
        '\u1056-\u1059',
        '\u105E-\u1060',
        '\u1062-\u1064',
        '\u1067-\u106D',
        '\u1071-\u1074',
        '\u1082-\u108D',
        '\u108F',
        '\u135F',
        '\u1712-\u1714',
        '\u1732-\u1734',
        '\u1752',
        '\u1753',
        '\u1772',
        '\u1773',
        '\u17B6-\u17D3',
        '\u17DD',
        '\u180B-\u180D',
        '\u18A9',
        '\u1920-\u192B',
        '\u1930-\u193B',
        '\u19B0-\u19C0',
        '\u19C8',
        '\u19C9',
        '\u1A17-\u1A1B',
        '\u1B00-\u1B04',
        '\u1B34-\u1B44',
        '\u1B6B-\u1B73',
        '\u1B80-\u1B82',
        '\u1BA1-\u1BAA',
        '\u1C24-\u1C37',
        '\u1DC0-\u1DE6',
        '\u1DFE',
        '\u1DFF',
        '\u20D0-\u20F0',
        '\u2DE0-\u2DFF',
        '\u302A-\u302F',
        '\u3099',
        '\u309A',
        '\uA66F-\uA672',
        '\uA67C',
        '\uA67D',
        '\uA802',
        '\uA806',
        '\uA80B',
        '\uA823-\uA827',
        '\uA880',
        '\uA881',
        '\uA8B4-\uA8C4',
        '\uA926-\uA92D',
        '\uA947-\uA953',
        '\uAA29-\uAA36',
        '\uAA43',
        '\uAA4C',
        '\uAA4D',
        '\uFB1E',
        '\uFE00-\uFE0F',
        '\uFE20-\uFE26'
    ]
    var graphemeExtend = new RegExp('(.)([' + chars.join('') + ']+)', 'g')
    // Temporarily reverse
    string = string.replace(graphemeExtend, '$2$1')
    return string.split('').reverse().join('')
}
