;
function setDashScheduleElements(d) {

    var currentWeekContainer = $('.__currentWeek');
    var prevWeekButton = $('.__prevWeek');
    var nextWeekButton = $('.__nextWeek');

    moment.locale(config.locale);

    prevWeekButton.data('date',d.clone().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'));
    nextWeekButton.data('date',d.clone().add(7, 'days').format('YYYY-MM-DD HH:mm:ss'));
    currentWeekContainer.data('date',d.format('YYYY-MM-DD HH:mm:ss'));

    prevWeekButton.attr('data-date',d.clone().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'));
    nextWeekButton.attr('data-date',d.clone().add(7, 'days').format('YYYY-MM-DD HH:mm:ss'));
    currentWeekContainer.attr('data-date',d.format('YYYY-MM-DD HH:mm:ss'));


    currentWeekContainer.empty().html('<strong>' + Translator.trans('dash.guiText.week', {}, 'fe_texts') + ' ' + d.format('ww') + '</strong><br />' +  d.clone().format('YYYY-MM-DD') + ' — ' + d.clone().endOf('isoWeek').format('YYYY-MM-DD') );

}
function getDashScheduleJson() {
    destroyToolTips('[data-toggle="tooltip"]');

    if (elementExists('#dynamicFormModal')) {
        $('#dynamicFormModal').modal('hide');
    }

    d = $('.__currentWeek').data('date');
    var dashScheduleJsonSource = $('[data-schedule-json]').data('source');
    var currentWeekContainer = $('.__currentWeek');

    showLoader();

        _request(dashScheduleJsonSource, 'post', { forDate : currentWeekContainer.data('date') }, function(r) {

            if (r.status) {

                $('[data-schedule-json]').val(JSON.stringify(r.data)).trigger('change');


            } else {
                $('[data-schedule-json]').val('{}').trigger('change');
            }

        }, {});

    hideLoader();


}
function renderDashScheduleTable() {
    var scheduleJson = $('[data-schedule-json]').val();
    var dashScheduleContainer = $('.__dashScheduleContainer');
    var tmpDate = moment($('.__currentWeek').data('date'));
    var loggedDoctorId = $('[data-schedule-json]').data('loggeddoctorid');
    var dailyBoxContainer = $('.dailyInfoBox .jobs');
    var dailyBoxHtml = [];

    if (!isJSONString(scheduleJson)) {
        $('[data-schedule-json]').val('{}');
    }
    scheduleJson = JSON.parse($('[data-schedule-json]').val());
    var userInfoUrl = $('[data-schedule-json]').data('userInfoUrl').replace('__uuid__','');

    var weekDayNames = getWeekDayNames();
    _.map(doctors, function(v, k) {

        if (elementExists('[data-tabcontent-id="doctor_tab_' + v.id + '"]')) {

            var tableContainer = $('[data-tabcontent-id="doctor_tab_' + v.id + '"]');

            var table = '<table class="timeTableScheduler" doctor-id="' + v.id + '">';
            table += '<thead>' +
                '<tr>' +
                '<th></th>';

            table += '</tr>' +
                '</thead><tbody>';

            var startTime = 7 * 60;
            var endTime = 20 * 60 + 30;

            for (i = startTime;i<=endTime;i = i + 30) {
                var hour = str_pad((Math.floor(i/60)), 2, '0', 'STR_PAD_LEFT');
                var minute = str_pad((i % 60), 2, '0', 'STR_PAD_RIGHT');
                var timeToShow = hour + ':' + minute;

                table += '<tr class="' + (minute !== '00' ? ' dotted ': '') + '">';

                for (j = 0; j < 8; j++) {

                    if (j == 0) {
                        table += '<th scope="col">' + timeToShow + '</th>';
                    } else {
                        var tmpClass = '';
                        var clientData = '';
                        var toolTip = '';
                        var cellContentAddon = '';
                        var tmpDateLoop = tmpDate.clone().isoWeekday(j).format('YYYY-MM-DD');


                        if (_.isObject(scheduleJson[v.id][j]) && _.isObject(scheduleJson[v.id][j][timeToShow]) ) {


                            if (scheduleJson[v.id][j][timeToShow].status) {

                                tmpClass += ' reserved ';
                                toolTip = ' data-toggle="tooltip" data-placement="bottom" title="' + Translator.trans('dash.guiText.cellReservedTooltip', {}, 'fe_texts') + '"';


                                if (scheduleJson[v.id][j][timeToShow].status == 0) {
                                    tmpClass += ' pending ';
                                    toolTip = ' data-toggle="tooltip" data-placement="bottom" title="' + Translator.trans('dash.guiText.cellPendingTooltip', {}, 'fe_texts') + '"';

                                }

                                if (scheduleJson[v.id][j][timeToShow].status == 2) {
                                    tmpClass += ' closed ';
                                    toolTip = ' data-toggle="tooltip" data-placement="bottom" title="' + Translator.trans('dash.guiText.cellCaseClosedTooltip', {}, 'fe_texts') + '"';

                                }

                                clientData = ' data-case-uuid="' + scheduleJson[v.id][j][timeToShow].caseUuid + '" data-user-id="' + scheduleJson[v.id][j][timeToShow].user.id + '" data-user-name="' + scheduleJson[v.id][j][timeToShow].user.name + '" ';
                                cellContentAddon = '<div>' +
                                    '<span class="left">' +
                                    '<a href="' + userInfoUrl + scheduleJson[v.id][j][timeToShow].user.uuid + '" class="_getContentInModal ' + (scheduleJson[v.id][j][timeToShow].user.id == scheduleJson[v.id][j][timeToShow].reservedBy ? ' samePerson ' : '') + ' " title="' + scheduleJson[v.id][j][timeToShow].user.name + '" ' +
                                    ' data-toggle="tooltip" ' +
                                    ' data-modalbody="" ' +
                                    ' data-preventdefault="true" ' +
                                    ' data-modalclass="modal-xxl" ' +
                                    ' data-modaltitle="' + Translator.trans('reservation.modal.userInfoModalTitle', {}, 'fe_texts') + '" ' +
                                    ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                    ' data-modalhasfooter="false" ' +
                                    ' data-loadmodalcontentfrom="href" ' +
                                    '><i class="fal fa-user-tie"></i></a>' +
                                    '<a href="/users/edit/' + scheduleJson[v.id][j][timeToShow].user.uuid + '" target="_blank"><i class="fal fa-user-edit"></i></a>';



                                    if (scheduleJson[v.id][j][timeToShow].status != 2 && strlen(scheduleJson[v.id][j][timeToShow].notes)>0 ) { // && (scheduleJson[v.id][j][timeToShow].notes != 'NULL' && scheduleJson[v.id][j][timeToShow].notes !== null)
                                        cellContentAddon += '<a href="' + str_replace('__reservationUuid__',scheduleJson[v.id][j][timeToShow].caseUuid, $('[data-schedule-json]').data('editreservationnotesUrl')) + '" ' +
                                            'class="_editInModalBinded" title="' + Translator.trans('reservation.modal.infoButtonTitle', {}, 'fe_texts') + '" data-toggle="tooltip" ' +
                                            ' data-modalbody="" ' +
                                            ' data-preventdefault="true" ' +
                                            ' data-loadmodalcontentfrom="href" ' +
                                            ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                            ' data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel', {}, 'fe_texts') + '" ' +
                                            ' data-modalokbuttonlabel="' + Translator.trans('generic.button.ok', {}, 'fe_texts') + '" ' +
                                            ' data-modaltitle="' + Translator.trans('reservation.modal.modalTitle', {}, 'fe_texts') + '" ' +
                                        '><i class="fal fa-info-square"></i></a>'
                                    }

                                    cellContentAddon +='</span>' +
                                    '<span class="right">';

                                    if (scheduleJson[v.id][j][timeToShow].status == 1) {

                                        cellContentAddon +=  '<a class="__resolveCase _editInModalBinded" ' +
                                            ' href="' + str_replace('__reservationUuid__',scheduleJson[v.id][j][timeToShow].caseUuid, $('[data-schedule-json]').data('closereservationUrl')) + '" title="' + Translator.trans('dash.guiText.solveCaseToolTip', {}, 'fe_texts') + '" ' +
                                            ' data-toggle="tooltip" ' +
                                            ' data-modalbody="" ' +
                                            ' data-preventdefault="true" ' +
                                            ' data-loadmodalcontentfrom="href" ' +
                                            ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                            ' data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel', {}, 'fe_texts') + '" ' +
                                            ' data-modalokbuttonlabel="' + Translator.trans('generic.button.ok', {}, 'fe_texts') + '" ' +
                                            ' data-modaltitle="' + Translator.trans('reservation.modal.resolveRecordTitle', {}, 'fe_texts') + '" ' +
                                            '><i class="fal fa-check-square"></i></a>';
                                    }


                                    if (scheduleJson[v.id][j][timeToShow].status != 2) {

                                        cellContentAddon +=  '<a class="__removeCase" href="javascript:;" title="' + Translator.trans('dash.guiText.deleteCaseToolTip', {}, 'fe_texts') +
                                        '" data-toggle="tooltip"' +
                                        ' data-preventdefault="true" ' +
                                        ' data-processaction="callback" ' +
                                        ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                        ' data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel', {}, 'fe_texts') + '" ' +
                                        ' data-modalokbuttonlabel="' + Translator.trans('generic.button.ok', {}, 'fe_texts') + '" ' +
                                        ' data-modalokbuttonclass="btn-danger" ' +
                                        ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                        ' data-modaltitle="' + Translator.trans('reservation.modal.deleteRecordTitle', {}, 'fe_texts') + '" ' +
                                        ' data-modalbody="' + Translator.trans('reservation.modal.deleteRecordBody', {}, 'fe_texts') + '" ' +
                                        '><i class="fal fa-times-square"></i></a>';

                                    }

                                    cellContentAddon += '</span></div>';
                            } else {
                                tmpClass += ' available _editInModalBinded ';
                                toolTip = ' data-toggle="tooltip" title="' + Translator.trans('dash.guiText.makeAReservation', {}, 'fe_texts') + '"';
                                clientData += ' data-modalbody="" ' +
                                    ' data-loadmodalcontentfrom="' + ( str_replace('__date__', (tmpDateLoop + ' ' + timeToShow + ':00'), str_replace('__doctorId__',v.id, $('[data-schedule-json]').data('newreservationUrl'))) ) + '" ' +
                                    ' data-preventdefault="true" ' +
                                    ' data-loadmodalcontentfrom="href" ' +
                                    ' data-modalclosebuttonlabel="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '" ' +
                                    ' data-modalcancelbuttonlabel="' + Translator.trans('generic.button.cancel', {}, 'fe_texts') + '" ' +
                                    ' data-modalokbuttonlabel="' + Translator.trans('generic.button.ok', {}, 'fe_texts') + '" ' +
                                    ' data-modaltitle="' + Translator.trans('reservation.modal.newRecordTitle', {}, 'fe_texts') + '" ';




                            }


                        }

                        table += '<td ' +
                            'class=" ' + tmpClass + ' "' +
                            'data-doctorid="' + v.id + '" data-hour="' + timeToShow + '" data-date="' + (tmpDateLoop + ' ' + timeToShow + ':00') + '" data-day="' + j + '" ' + clientData + toolTip + '>';

                        table += cellContentAddon + '</td>';
                    }


                }

                table += '</tr>';
            }


            table += '</tbody></table>';

            table = $(table);

            for (var d = 1; d<8; d++) {
                var tableHeadCell = $('<th>' + tmpDate.clone().isoWeekday(d).format('DD') + ' – ' + weekDayNames[d] + '</th>');

                if (_.isObject(scheduleJson[v.id][d]) && _.size(scheduleJson[v.id][d]) > 0 ) {
                    var tmpFiltered = _.filter(scheduleJson[v.id][d], function(tmpCI){ return (tmpCI.status == 1 || tmpCI.status == 2); });

                    if (_.isObject(tmpFiltered) && _.size(tmpFiltered) > 0) {
                        var tmpListButton = $('<i class="fal fa-clipboard-list-check _dailyInfoButton"></i>');
                        tmpListButton.data('data', tmpFiltered);

                        tableHeadCell.append(tmpListButton);

                    }

                }

                tableHeadCell.appendTo(table.find('thead tr'));

            }

            tableContainer.empty().append(table);

            getDailyIncome();

        }

    });

    getTodaysJobs();
    config.userInteractionStarted = false;
    destroyToolTips('[data-toggle="tooltip"]');
}

function getTodaysJobs() {
    var dailyBoxContainer = $('.dailyInfoBox .jobs');
    var dailyJobsJsonSource = $('[data-schedule-json]').data('dailyjobssource');
    $('.dailyInfoBox .jobs').css('maxHeight', $('.strechOnXl').outerHeight() - 56);

    _request(dailyJobsJsonSource, 'post', { doctor : $('[data-schedule-json]').data('loggeddoctorid') }, function(r) {

        if (r.status && _.isObject(r.data) && _.size(r.data)>0) {
            var tmpdailyBoxHtml = '<ul>';
            var dailyBoxJson = _.sortBy(r.data, 'date');

            _.map(dailyBoxJson, function(v,k){

                tmpdailyBoxHtml += '<li>';

                tmpdailyBoxHtml += '<div class="hour">' + v.hour + '</div>';
                tmpdailyBoxHtml += '<div class="name">' + v.name + '</div>';
                tmpdailyBoxHtml += '<div class="notes">' + v.notes + '</div>';

                tmpdailyBoxHtml += '</li>';

            });

            tmpdailyBoxHtml += '</ul>';
            dailyBoxContainer.html(tmpdailyBoxHtml);

        } else {
            dailyBoxContainer.html(Translator.trans('dash.dailyJobs.noJobs', {}, 'fe_texts') );
        }

    }, {});
}

function getDailyIncome() {
    return;
    var c = $('.__dailyIncomeContainer .income');
    var url = $('[data-schedule-json]').data('getdailyincomeurl');
    var docId = $('[data-schedule-json]').data('loggeddoctorid');
    var day = moment().format('YYYY-MM-DD');

    _request(url, 'post', { forDate : day, docId : docId }, function(r) {

        c.html(r.data.income);

    }, {});
}

$(document).ready(function() {

    if ($('body').hasClass('__dashx')) {

        var currentWeekContainer = $('.__currentWeek');
        var prevWeekButton = $('.__prevWeek');
        var nextWeekButton = $('.__nextWeek');

        var dashScheduleJson = $('[data-schedule-json]').val();
        if (!isJSONString(dashScheduleJson)) $('[data-schedule-json]').val('{}');

        moment.locale(config.locale);
        var now = moment().startOf('isoWeek');

        setDashScheduleElements(now);
        getDashScheduleJson();


        $("body").off("*", '[data-schedule-json]', "**");
        $("[data-schedule-json]").on("change", function () {
            renderDashScheduleTable();
        });

        $("body").off("*", '.__prevWeek, .__nextWeek', "**");
        $("body").on("click", '.__prevWeek, .__nextWeek', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var self = $(this);
            var d = moment(self.data('date')).startOf('isoWeek');

            setDashScheduleElements(d);
            getDashScheduleJson();
        });


        $("body").off("*", '.__removeCase', "**");
        $("body").on("click", '.__removeCase', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var self = $(this);
            var uuid = self.closest('td').data('caseUuid');

            showConfirmModal(self, function() {

                showLoader();

                _request($('[data-schedule-json]').data('deletereservationUrl'), 'post', { records : [ uuid ] }, function(r) {

                    if (!r.status) {

                        notify('error', r.msg);

                    }

                }, {});

                hideLoader();

                $('#_confirmationModal').modal('hide');
                getDashScheduleJson();

            });

        });



        $("body").off("*", '.showReservationInfo', "**");
        $("body").on("click", '.showReservationInfo', function (event) {
            event.preventDefault();
            event.stopPropagation();

            showConfirmModal($(this));
        });

        $("body").off("*", '._dailyInfoButton', "**");
        $("body").on("click", '._dailyInfoButton', function (event) {
            event.preventDefault();
            event.stopPropagation();
            config.userInteractionStarted = true;
            var self  = $(this);

            if (typeof self.data('data') !== 'undefined' && _.isObject(self.data('data')) && _.size(self.data('data')) > 0) {
                var tmp = '<table class="table table-sm table-striped _dailyTastksTable"><thead>' +
                    '<tr>' +
                    '<th class="max-width-6rem">' + Translator.trans('dash.guiText.dailyListViewModal.table.hour', {}, 'fe_texts') + '</th>' +
                    '<th>' + Translator.trans('dash.guiText.dailyListViewModal.table.clientName', {}, 'fe_texts') + '</th>' +
                    '<th>' + Translator.trans('dash.guiText.dailyListViewModal.table.notes', {}, 'fe_texts') + '</th>' +
                    '<th class="text-right max-width-6rem">' + Translator.trans('dash.guiText.dailyListViewModal.table.status', {}, 'fe_texts') + '</th>' +
                    '</tr></thead><tbody>';

                var data = _.sortBy(self.data('data'), 'date');

                _.map(data, function(v,k) {
                    var tmpClass = '';
                    var tmpText = '';

                    switch(v.status) {
                        case '0':
                            var tmpClass = 'pending';
                            var tmpText = Translator.trans('reservation.status.withIcon.pending', {}, 'fe_texts');
                        break;
                        case '1':
                            var tmpClass = 'reserved';
                            var tmpText = Translator.trans('reservation.status.withIcon.reserved', {}, 'fe_texts');
                        break;
                        case '2':
                            var tmpClass = 'closed';
                            var tmpText = Translator.trans('reservation.status.withIcon.closed', {}, 'fe_texts');
                        break;
                        default:
                            var tmpClass = '';
                            var tmpText = '';
                        break;
                    }


                    tmp += '<tr>' +
                        '<td class="max-width-6rem ' + tmpClass + '">' + moment(v.date).format('HH:mm') + '</td>' +
                        '<td class="' + tmpClass + '">' + v.user.name + '</td>' +
                        '<td class="' + tmpClass + '">' + v.notes + '</td>' +
                        '<td class="text-right max-width-6rem ' + tmpClass + '">' + tmpText + '</td>' +
                        '</tr>';
                });

                tmp += '</tbody></table>';


                if (elementExists('#dynamicContentModal')) {
                    $('#dynamicContentModal').remove();
                }

                var modal = '<div class="modal fade" id="dynamicContentModal" ' +
                    ' role="dialog" aria-labelledby="dynamicContentModalTitle" aria-hidden="true">' +
                    '        <div class="modal-dialog modal-xl" role="document">' +
                    '            <div class="modal-content">';


                modal += '<div class="modal-header">' +
                    '<h5 class="modal-title" id="dynamicContentModalTitle">' + Translator.trans('dash.guiText.dailyListViewModal.title', {}, 'fe_texts') + '</h5>' +
                    '<button type="button" class="close" data-dismiss="modal" aria-label="' + Translator.trans('generic.button.close', {}, 'fe_texts') + '"><span aria-hidden="true"></span></button>' +
                    '</div>';


                modal += '<div class="modal-body">' + tmp + '</div>';

                modal += '</div>' +
                    '</div>' +
                    '</div>';

                $('body').append($(modal));

                $('#dynamicContentModal').on('hide.bs.modal', function (e) {
                    $('#dynamicContentModal .modal-body').empty();

                    config.userInteractionStarted = false;
                });

                $('#dynamicContentModal').on('shown.bs.modal', function (e) {
                    config.userInteractionStarted = true;
                    destroyToolTips('[data-toggle="tooltip"]');
                });


                $('#dynamicContentModal').modal('show');
            }

        });

        $("body").off("*", '.dailyInfoBox .handle', "**");
        $("body").on("click", '.dailyInfoBox .handle', function (event) {
            event.preventDefault();
            event.stopPropagation();

            $('.strechOnXl').toggleClass('streched');
            $('.dailyInfoBox').toggleClass('opened');
            $('.dailyInfoBox .handle').toggleClass('active');

        });

        setInterval(function() {
            if (!config.userInteractionStarted) {
                destroyToolTips('[data-toggle="tooltip"]');
                getDashScheduleJson();
            }
        }, config.dashReloadTime);

    }

});


