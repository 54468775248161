;
function getWeekDayNames() {
    moment.locale(config.locale);
    var r = [0];

    for (i=1; i<8; i++) {
        r.push(moment().isoWeekday(i).format('dddd'));
    }

    return r;

}

function renderTimeTablesForEditing(doctors, timeTableJson) {

    var weekDayNames = getWeekDayNames();

    _.map(doctors, function(v, k) {


        if (elementExists('[data-tabcontent-id="doctor_tab_' + v.uuid + '"]')) {
            var tableContainer = $('[data-tabcontent-id="doctor_tab_' + v.uuid + '"]');

            var table = '<table class="timeTable" doctor-id="' + v.uuid + '">';
            table += '<thead>' +
                '<tr>' +
                '<th></th>' +
                '<th>' + weekDayNames[1] + '</th>' +
                '<th>' + weekDayNames[2] + '</th>' +
                '<th>' + weekDayNames[3] + '</th>' +
                '<th>' + weekDayNames[4] + '</th>' +
                '<th>' + weekDayNames[5] + '</th>' +
                '<th>' + weekDayNames[6] + '</th>' +
                '<th>' + weekDayNames[7] + '</th>' +
                '</tr>' +
                '</thead><tbody>';

            var startTime = 7 * 60;
            var endTime = 20 * 60 + 30;

            for (i = startTime;i<=endTime;i = i + 30) {
                var hour = str_pad((Math.floor(i/60)), 2, '0', 'STR_PAD_LEFT');
                var minute = str_pad((i % 60), 2, '0', 'STR_PAD_RIGHT');
                var timeToShow = hour + ':' + minute;

                table += '<tr class="' + (minute !== '00' ? ' dotted ': '') + '">';

                for (j = 0; j < 8; j++) {

                    if (j == 0) {
                        table += '<th scope="col">' + timeToShow + '</th>';
                    } else {
                        var tmpClass = '';

                        //cl(timeTableJson.doctors[v.uuid]);

                        if (_.isObject(timeTableJson.doctors[v.uuid][j]) && in_array(timeToShow, timeTableJson.doctors[v.uuid][j]) ) {
                            tmpClass = 'reserved';
                        }

                        table += '<td ' +
                            'class=" ' + tmpClass + ' "' +
                            'data-doctorid="' + v.uuid + '" data-hour="' + timeToShow + '" data-day="' + j + '">';

                        table += '</td>';
                    }


                }

                table += '</tr>';
            }


            table += '</tbody></table>';

            tableContainer.empty().append($(table));

        }

    });

}

$(document).ready(function() {

    if (elementExists('[data-timetable-id="timeTable"]') && typeof doctors !== 'undefined') {
        var timeTableElement = $('[data-timetable-id="timeTable"]');
        var timeTableJson;


        if (!isJSONString(doctors)) {
            doctors = {};
        } else {
            doctors = JSON.parse(doctors);
        }

        if (!isJSONString(timeTableElement.val())) {
            timeTableJson = {};
        } else {
            timeTableJson = JSON.parse(timeTableElement.val());
        }

        if (timeTableJson.doctors == undefined) {

            timeTableJson.doctors = {};
            _.map(doctors, function(v, k) {
                timeTableJson.doctors[v.uuid] = {
                    1 : [],
                    2 : [],
                    3 : [],
                    4 : [],
                    5 : [],
                    6 : [],
                    7 : []
                };
                timeTableJson.doctors[v.uuid]['doctorId'] = v.id;
            });
        }

        if (_.size(doctors)>0) {


            _.map(doctors, function(v, k) {

                if (!_.isObject(timeTableJson.doctors[v.uuid])) {
                    timeTableJson.doctors[v.uuid] = {
                        1 : [],
                        2 : [],
                        3 : [],
                        4 : [],
                        5 : [],
                        6 : [],
                        7 : []
                    };
                    timeTableJson.doctors[v.uuid]['doctorId'] = v.id;
                }

            });


            $("body").off("*", timeTableElement, "**");
            $("body").on("change", timeTableElement, function () {
                renderTimeTablesForEditing(doctors, timeTableJson);
            });

            $("body").off("click", '.timeTable tbody tr td', "**");
            $("body").on("click", '.timeTable tbody tr td', function () {
                var self = $(this);

                if (dataPropExists(self,'doctorid') && dataPropExists(self,'hour') && dataPropExists(self,'day') &&
                    strlen(self.data('doctorid')) > 0 && strlen(self.data('hour')) && strlen(self.data('day')) &&
                    in_array(self.data('day'),[1,2,3,4,5,6,7]) &&
                    doctors.filter(doctor => doctor.uuid == self.data('doctorid'))
                ) {

                    var cell = {
                        doctorId : self.data('doctorid'),
                        hour : self.data('hour'),
                        day : self.data('day'),

                    };

                    if (_.isObject(timeTableJson.doctors[cell.doctorId][cell.day]) && in_array(cell.hour, timeTableJson.doctors[cell.doctorId][cell.day])) {
                        timeTableJson.doctors[cell.doctorId][cell.day] = _.without(timeTableJson.doctors[cell.doctorId][cell.day], cell.hour);
                    } else {
                        timeTableJson.doctors[cell.doctorId][cell.day].push(cell.hour);

                    }

                    timeTableElement.val(JSON.stringify(timeTableJson)).trigger('change');

                }

            });

            timeTableElement.trigger('change');


        } else {
            timeTableElement.val('{}');
        }

    }

});