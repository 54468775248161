;

$(document).ready(function() {

    if (elementExists('.vich-image input[type="checkbox"]')) {
        $('.vich-image input[type="checkbox"]').prop('checked', false);
    }

    $("body").off("change", '._uploadFileContainer input[type="file"]', "**");
    $("body").on("change", '._uploadFileContainer input[type="file"]', function (e) {
        var label = $(this).parent().find('label');

        var fileName = false;

        if (_.isObject(this.files) && _.size(this.files) > 0) {
            fileName = e.target.value.split( '\\' ).pop();
        } else {
            if (dataPropExists(label, 'defaulttext')) {
                fileName = getDataProp(label,'defaulttext');
            }
        }

        if (fileName != false) {
            label.html(fileName);
        } else {
            label.html('&nbsp;');
        }

    });



    if (elementExists('.__uploadInput')) {

        $('.__uploadInput').each(function(e) {
            var self = $(this);

            if ( dataPropExists(self, 'showpreview') && self.data('showpreview') == true &&
                dataPropExists(self,'previewcontainer') && elementExists(self.data('previewcontainer'))
            ) {
                var prevContainer = $(self.data('previewcontainer'));

                self.on("change",  function(e) {

                    if (_.isObject(this.files) && _.size(this.files) > 0) {

                        if (in_array(this.files[0]["type"],config.validImageTypes)) {

                            var reader = new FileReader();

                            reader.onload = function (e) {
                                // get loaded data and render thumbnail.
                                // document.getElementById(label.data('previewId')).src = e.target.result;
                                prevContainer.css('background', 'transparent url(' + e.target.result + ') center center no-repeat');
                                prevContainer.css('backgroundSize', 'cover');
                                prevContainer.addClass('img-covered');

                                if (elementExists('.__vichImageFakeDeleteChBox')) {
                                    $('.__vichImageFakeDeleteChBox').parent().remove();
                                }

                            };

                            // read the image file as a data URL.
                            reader.readAsDataURL(this.files[0]);

                        }

                    }

                });

            }

        });
    }

});