;
$(document).ready(function() {

    Dropzone.autoDiscover = false;
    var dropZone;

    $("body").off("click", "._uploadNewFilesDropZoneModalOpenButton", "**");
    $("body").on("click", "._uploadNewFilesDropZoneModalOpenButton", function (e) {
        e.preventDefault();
        e.stopPropagation();

        var self = $(this);

        var ops = {
            targetUrl : self.attr('href'),
            backdrop : 'true',
            keyboard : 'true',
            modalClass : '',
            modalTitle : '',
            modalCloseButtonLabel : 'close',
            modalCancelButtonClass : '',
            modalCancelButtonLabel : 'cancel',
            modalOkButtonClass : '',
            modalOkButtonLabel : 'ok',
            token : '',
            params : null,
            acceptedFiles : config.dropZoneAcceptedFiles
        };

        if (dataPropExists(self,'modalbackdrop') && in_array(self.data('modalbackdrop'),[ true, false, 'static' ])) {
            ops.backdrop = self.data('modalbackdrop');
        }
        if (dataPropExists(self,'modalkeyboard') && in_array(self.data('modalkeyboard'),[ true, false ])) {
            ops.keyboard = self.data('modalkeyboard');
        }
        if (dataPropExists(self,'modalclass')) {
            ops.modalClass = self.data('modalclass');
        }
        if (dataPropExists(self,'modaltitle') && strlen(self.data('modaltitle'))>0) {
            ops.modalTitle = self.data('modaltitle');
        }
        if (dataPropExists(self,'modalclosebuttonlabel') && strlen(self.data('modalclosebuttonlabel'))>0) {
            ops.modalCloseButtonLabel = self.data('modalclosebuttonlabel');
        }
        if (dataPropExists(self,'modalcancelbuttonclass') && strlen(self.data('modalcancelbuttonclass'))>0) {
            ops.modalCancelButtonClass = self.data('modalcancelbuttonclass');
        }
        if (dataPropExists(self,'modalcancelbuttonlabel') && strlen(self.data('modalcancelbuttonlabel'))>0) {
            ops.modalCancelButtonLabel = self.data('modalcancelbuttonlabel');
        }
        if (dataPropExists(self,'modalokbuttonclass') && strlen(self.data('modalokbuttonclass'))>0) {
            ops.modalOkButtonClass = self.data('modalokbuttonclass');
        }
        if (dataPropExists(self,'modalokbuttonlabel') && strlen(self.data('modalokbuttonlabel'))>0) {
            ops.modalOkButtonLabel = self.data('modalokbuttonlabel');
        }
        if (dataPropExists(self,'csfrtoken') ) {
            ops.token = self.data('csfrtoken');
        }
        if (dataPropExists(self,'acceptedfiles') ) {
            ops.acceptedFiles = self.data('acceptedfiles');
        }
        if (dataPropExists(self,'params') ) {
            ops.params = self.data('params');
        }

        if (elementExists('#dropZoneModal')) { $('#dropZoneModal').remove(); }

        var modal = '<div class="modal fade" id="dropZoneModal" ' +
            'data-backdrop="' + ops.backdrop + '" ' +
            'data-keyboard="' + ops.keyboard + '" ' +
            'data-targeturl="' + ops.targetUrl + '" ' +
            'tabindex="-1" role="dialog" aria-labelledby="dropZoneModalTitle" aria-hidden="true">' +
            '        <div class="modal-dialog modal-lg ' + ops.modalClass + '" role="document">' +
            '            <div class="modal-content">';

        if (strlen(ops.modalTitle)>0) {
            modal += '<div class="modal-header">' +
                '<h5 class="modal-title" id="dropZoneModalTitle">' + ops.modalTitle + '</h5>' +
                '<button type="button" class="close" data-dismiss="modal" aria-label="' + ops.modalCloseButtonLabel + '"><span aria-hidden="true"></span></button>' +
                '</div>';
        }

        modal += '<div class="modal-body"><div class="_dropZoneInModal dropzone" id="_dropZoneInModal"></div></div>' +
            '<div class="modal-footer justify-content-between">' +
            '<button type="button" class="btn btn-secondary ' + ops.modalCancelButtonClass + '" data-dismiss="modal" title="' + ops.modalCancelButtonLabel + '">' + ops.modalCancelButtonLabel + '</button>' +
            '<button type="button" class="btn btn-primary d-none ' + ops.modalOkButtonClass + ' _process" title="' + ops.modalOkButtonLabel + '">' + ops.modalOkButtonLabel + '</button>';

        modal +=    '</div>' +
            '</div>' +
            '</div>' +
            '</div>';


        $('body').append($(modal));

        $('#dropZoneModal').on('shown.bs.modal', function (e) {

            dropZone = dz("#_dropZoneInModal", {
                url: ops.targetUrl,

                dictDefaultMessage : 'Click or drop files here.',
                dictInvalidFileType : 'Invalid file type.',
                dictFileTooBig : 'File size too big ({{filesize}}). Max. file size: {{maxFilesize}} MB.',
                dictRemoveFile : 'remove',
                dictCancelUpload : 'cancel',
                dictResponseError : 'Server error.',
                acceptedFiles : ops.acceptedFiles,

                maxFilesize: config.maxFilesize, // MB

                params : {
                    token : ops.token
                },

                init: function(f) {
                    var submitButton = $("#dropZoneModal ._process");

            /*        this.on("success", function(file,response) {
                        cl(response);
                    }),*/

               /*     this.on("complete", function(response) {
                        showLoader();
                        reloadUrl();
                    }),
                    this.on("completemultiple", function(response) {
                        showLoader();
                        reloadUrl();
                    }),

                    this.on("complete", function(response) {
                        cl(response);
                        showLoader();
                        reloadUrl();
                    }),*/
                  /*  this.on("totaluploadprogress", function(progr) {
                        cl(this.getUploadingFiles());
                    }),*/
                    /*this.on("complete", function(file) {
                      this.removeFile(file);
                  }),*/


                    this.on("success", function() {
                        if (_.size(this.getUploadingFiles())<1) {
                            showLoader();
                            reloadUrl();
                        }
                    }),
                    this.on("maxfilesexceeded", function(file){
                        this.removeAllFiles();
                        this.addFile(file);

                        cl('max files reached');
                    }),
                    this.on("addedfile", function() {
                        submitButton.removeClass('d-none');
                    }),

                    this.on("sending", function (file, xhr, formData) {
                        if (_.isObject(ops.params) && _.size(ops.params) > 0) {
                            _.map(ops.params, function(v,k) {
                                formData.append(k, v);
                            });
                        }
                    }),

                    this.on("reset", function() {
                        submitButton.addClass('d-none');
                    })

                }
            });

            $("body").off("click", "#dropZoneModal ._process", "**");
            $("body").on("click", "#dropZoneModal ._process", function (e) {
                dropZone.processQueue();
            });


        });


        $('#dropZoneModal').modal('show');
    });



});